var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "vehcileScheduling",
      staticClass: "vehcile-scheduling",
      class: { "ticket-change-page": _vm.isShowTicketChange },
    },
    [
      _c(
        "el-tabs",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isShowTicketChange,
              expression: "!isShowTicketChange",
            },
          ],
          style: _vm.rememberTABLES == "待配车" ? "background:#F7F8F9" : "",
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.tabClickFun },
        },
        [
          false
            ? _c(
                "el-tab-pane",
                { staticClass: "el-tab-pane1", attrs: { label: "待配车" } },
                [
                  _c("div", { staticClass: "carTree" }, [
                    _c("div", { staticClass: "carTree-btn" }, [
                      _c(
                        "span",
                        {
                          class: _vm.carTreeClass[0],
                          on: {
                            click: function ($event) {
                              return _vm.carTreeFun(0)
                            },
                          },
                        },
                        [_vm._v("今天")]
                      ),
                      _c(
                        "span",
                        {
                          class: _vm.carTreeClass[1],
                          on: {
                            click: function ($event) {
                              return _vm.carTreeFun(1)
                            },
                          },
                        },
                        [_vm._v("明天")]
                      ),
                      _c(
                        "span",
                        {
                          class: _vm.carTreeClass[2],
                          on: {
                            click: function ($event) {
                              return _vm.carTreeFun(2)
                            },
                          },
                        },
                        [_vm._v("后天")]
                      ),
                      _c(
                        "span",
                        {
                          class: _vm.carTreeClass[3],
                          on: {
                            click: function ($event) {
                              return _vm.carTreeFun(3)
                            },
                          },
                        },
                        [_vm._v("全部")]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "carTree-content" },
                      _vm._l(_vm.carTreeList, function (item, index) {
                        return _c(
                          "div",
                          { key: item.ids },
                          [
                            _c(
                              "p",
                              {
                                class: {
                                  active:
                                    _vm.rememberNAME == "line" &&
                                    _vm.rememberINDEX == index,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.checkedNodeTree(
                                      item.ids,
                                      "line",
                                      item.intercityOrderGroups,
                                      index
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont its_sm_luxian",
                                }),
                                _vm._v(" " + _vm._s(item.label) + " "),
                                item.passengerCount != 0
                                  ? _c(
                                      "span",
                                      { staticClass: "passengerSpan" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            "(" + item.passengerCount + ")"
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._l(item.children, function (jump, jndex) {
                              return _c(
                                "span",
                                {
                                  key: jump.ids,
                                  class: {
                                    active:
                                      _vm.rememberNAME == "time" &&
                                      _vm.rememberJNDEX == jndex &&
                                      _vm.rememberINDEX == index,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.checkedNodeTree(
                                        jump.ids,
                                        "time",
                                        jump.intercityOrderGroups,
                                        index,
                                        jndex
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(jump.label) + " "),
                                  jump.passengerCount != 0
                                    ? _c(
                                        "span",
                                        { staticClass: "passengerSpan" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              "(" + jump.passengerCount + ")"
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "carContent" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c(
                              "div",
                              {
                                ref: "form",
                                staticClass: "form-area",
                                staticStyle: { background: "#ffffff" },
                              },
                              [
                                _c(
                                  "el-form",
                                  {
                                    staticClass: "demo-form-inline",
                                    attrs: {
                                      inline: true,
                                      model: _vm.searchForm,
                                      "label-position": "left",
                                      "label-width": "74px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      [
                                        _vm.listFind("批量配车")
                                          ? [
                                              _c(
                                                "el-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.cardeployShow &&
                                                        _vm.batchCar,
                                                      expression:
                                                        "cardeployShow && batchCar",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "primary",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: () => {
                                                      this.cardMaskShow = true
                                                      this.marskArray = []
                                                      this.cardeployShow = false
                                                    },
                                                  },
                                                },
                                                [_vm._v("批量配车")]
                                              ),
                                            ]
                                          : _vm._e(),
                                        _c(
                                          "el-button",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.cardeployShow,
                                                expression: "!cardeployShow",
                                              },
                                            ],
                                            attrs: {
                                              type: "primary",
                                              size: "small",
                                            },
                                            on: {
                                              click: () => {
                                                this.cardMaskShow = false
                                                this.cardeployShow = true
                                              },
                                            },
                                          },
                                          [_vm._v("取消")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.cardeployShow,
                                                expression: "!cardeployShow",
                                              },
                                            ],
                                            attrs: {
                                              type: "primary",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.cardeployFun()
                                              },
                                            },
                                          },
                                          [_vm._v("确认配车")]
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                ref: "functionButtons",
                                staticClass: "function-buttons contentGrid",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.unData.length == 0,
                                        expression: "unData.length == 0",
                                      },
                                    ],
                                    staticClass: "contentGridnoData",
                                  },
                                  [_vm._v(" 暂无数据 ")]
                                ),
                                _vm._l(_vm.unData, function (strip) {
                                  return _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.unData.length > 0,
                                          expression: "unData.length > 0",
                                        },
                                      ],
                                      key: strip.departureDate,
                                      staticClass: "card-content",
                                    },
                                    [
                                      _c("div", { staticClass: "card-label" }, [
                                        _c("p", [
                                          _c("span", { staticClass: "Date" }, [
                                            _vm._v(_vm._s(strip.departureDate)),
                                          ]),
                                          _c("span", { staticClass: "total" }, [
                                            _c("div", [_vm._v("乘客数：")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(strip.passengerCount) +
                                                " "
                                            ),
                                          ]),
                                          _c("span", { staticClass: "total" }, [
                                            _c("div", [_vm._v("订单数：")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  strip.intercityOrderTotal
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("span", { staticClass: "total" }, [
                                            _c("div", [_vm._v("班次：")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  strip.shiftIntercityCount
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "gridContent" },
                                        _vm._l(
                                          strip.intercityOrders,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: item.id,
                                                staticClass: "card",
                                              },
                                              [
                                                _c("div", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.cardMaskShow,
                                                      expression:
                                                        "cardMaskShow",
                                                    },
                                                  ],
                                                  class:
                                                    _vm.marskArray.indexOf(
                                                      index
                                                    ) == -1
                                                      ? "mask"
                                                      : "active-mask" + " mask",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.maskFun(
                                                        index,
                                                        item
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  { staticClass: "line" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.startStation +
                                                            `-` +
                                                            item.endStation
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "time" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.departureDate +
                                                            ` ` +
                                                            item.departureTime
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c("el-divider"),
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "colorSpan",
                                                    },
                                                    [_vm._v("订单编号：")]
                                                  ),
                                                  _vm._v(
                                                    " " + _vm._s(item.id) + " "
                                                  ),
                                                ]),
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "colorSpan",
                                                    },
                                                    [_vm._v("下单人：")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.passengerPhone
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "colorSpan",
                                                    },
                                                    [_vm._v("联系人：")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.orderPhone) +
                                                      " "
                                                  ),
                                                ]),
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "colorSpan",
                                                    },
                                                    [_vm._v("乘客人数：")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.validPassengersNum
                                                      ) +
                                                      "人 "
                                                  ),
                                                ]),
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "colorSpan",
                                                    },
                                                    [_vm._v("上车点：")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.startAddress
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "colorSpan",
                                                    },
                                                    [_vm._v("下车点：")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.endAddress) +
                                                      " "
                                                  ),
                                                ]),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "10px",
                                                          "margin-bottom":
                                                            "10px",
                                                        },
                                                        attrs: {
                                                          clearable: "",
                                                          placeholder:
                                                            "请选择车牌号",
                                                        },
                                                        on: {
                                                          "visible-change":
                                                            function ($event) {
                                                              return _vm.visibleChangeCarId(
                                                                $event,
                                                                item
                                                              )
                                                            },
                                                        },
                                                        model: {
                                                          value: item.vehicleId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "vehicleId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.vehicleId",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.vehicleList,
                                                        function (e) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: e.id,
                                                              attrs: {
                                                                label:
                                                                  e.brand +
                                                                  "-" +
                                                                  e.licensePlateNumber +
                                                                  "-" +
                                                                  e.seatNumber +
                                                                  "座",
                                                                value: e.id,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                          size: "small",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onVehicle(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("确认")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          false
            ? _c(
                "el-tab-pane",
                { attrs: { label: "已配车，待派单" } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { ref: "form", staticClass: "form-area" },
                          [
                            _c(
                              "el-form",
                              {
                                staticClass: "demo-form-inline",
                                attrs: {
                                  inline: true,
                                  model: _vm.searchForm,
                                  "label-position": "left",
                                  "label-width": "80px",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "线路：" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请选择线路",
                                        },
                                        model: {
                                          value: _vm.searchForm.lineId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "lineId",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.lineId",
                                        },
                                      },
                                      _vm._l(_vm.lineList, function (role) {
                                        return _c("el-option", {
                                          key: role.id,
                                          attrs: {
                                            label: role.name,
                                            value: role.id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "发车日期：" } },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        format: "yyyy-MM-dd",
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "发车日期",
                                      },
                                      model: {
                                        value: _vm.searchForm.startDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "startDate",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.startDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getDataList(2)
                                          },
                                        },
                                      },
                                      [_vm._v("查询")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            ref: "functionButtons",
                            staticClass:
                              "function-buttons contentGrid contentGrid2",
                          },
                          _vm._l(_vm.ingData, function (item) {
                            return _c(
                              "div",
                              { key: item.id, staticClass: "card" },
                              [
                                _c("div", { staticClass: "line" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.startStation +
                                          `-` +
                                          item.endStation
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "time" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.departureDate +
                                          ` ` +
                                          item.departureTime
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("el-divider"),
                                _c("div", [
                                  _vm._v(
                                    "配车人数：" +
                                      _vm._s(item.passengerCount) +
                                      "人"
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    "未配车人数：" +
                                      _vm._s(item.surplusPassengerCount) +
                                      "人"
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    " 指派车辆：" +
                                      _vm._s(
                                        item.brand +
                                          `-` +
                                          item.licensePlateNumber
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  [
                                    _vm._v(" 指派司机： "),
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请选择司机",
                                        },
                                        on: {
                                          "visible-change": function ($event) {
                                            return _vm.visibleChange(
                                              $event,
                                              item
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.driverId,
                                          callback: function ($$v) {
                                            _vm.$set(item, "driverId", $$v)
                                          },
                                          expression: "item.driverId",
                                        },
                                      },
                                      _vm._l(_vm.driverList, function (e) {
                                        return _c("el-option", {
                                          key: e.id,
                                          attrs: {
                                            label:
                                              e.driverName +
                                              "-" +
                                              e.driverPhone,
                                            value: e.driverId,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "btn" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onDetail(item)
                                          },
                                        },
                                      },
                                      [_vm._v("配车详情")]
                                    ),
                                    _vm.listFind("派单")
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onDriver(item)
                                              },
                                            },
                                          },
                                          [_vm._v("确认派单")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onDeleteOrderFun(item)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-tab-pane",
            { attrs: { label: "已派单，待接乘客" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "div",
                        { ref: "form", staticClass: "form-area" },
                        [
                          _c(
                            "el-form",
                            {
                              staticClass: "demo-form-inline",
                              attrs: {
                                inline: true,
                                model: _vm.searchForm,
                                "label-position": "left",
                                "label-width": "80px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "线路：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择线路",
                                      },
                                      model: {
                                        value: _vm.searchForm.lineId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "lineId",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.lineId",
                                      },
                                    },
                                    _vm._l(_vm.lineList, function (role) {
                                      return _c("el-option", {
                                        key: role.id,
                                        attrs: {
                                          label: role.name,
                                          value: role.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "发车日期：" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      type: "date",
                                      placeholder: "发车日期",
                                    },
                                    model: {
                                      value: _vm.searchForm.startDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "startDate",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.startDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getDataList(3)
                                        },
                                      },
                                    },
                                    [_vm._v("查询")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          ref: "functionButtons",
                          staticClass: "function-buttons contentGrid tableGrid",
                        },
                        _vm._l(_vm.dData, function (item) {
                          return _c(
                            "div",
                            { key: item.id, staticClass: "card" },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: {
                                        "padding-left": "24px",
                                        "padding-top": "10px",
                                      },
                                      attrs: { span: 6 },
                                    },
                                    [
                                      _c("div", { staticClass: "line" }, [
                                        _vm._v(_vm._s(item.lineName)),
                                      ]),
                                      _c("div", { staticClass: "time" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.computedShiftIntercityTime(
                                                item
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("el-divider"),
                                      _c("div", [
                                        _vm._v(
                                          " 指派车辆：" +
                                            _vm._s(
                                              item.brand +
                                                `-` +
                                                item.licensePlateNumber +
                                                `-` +
                                                item.seatNumber +
                                                `座`
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          "指派司机：" + _vm._s(item.driverName)
                                        ),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          "联系电话：" +
                                            _vm._s(item.driverPhone)
                                        ),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          "已接人数：" +
                                            _vm._s(item.carryCount) +
                                            "人"
                                        ),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          "待接人数：" +
                                            _vm._s(item.lastCount) +
                                            "人"
                                        ),
                                      ]),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.SMSFun(item)
                                            },
                                          },
                                        },
                                        [_vm._v("短信群发")]
                                      ),
                                      _vm.listFind("查看车辆位置")
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onCheck(item)
                                                },
                                              },
                                            },
                                            [_vm._v("查看车辆位置")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 18 } },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          attrs: {
                                            data: item.detailVOS,
                                            stripe: "",
                                            height: "30vh",
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "passengerPhone",
                                              label: "下单人",
                                              width: "120",
                                              "show-overflow-tooltip": "",
                                              align: "center",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "orderPhone",
                                              label: "联系人",
                                              width: "120",
                                              "show-overflow-tooltip": "",
                                              align: "center",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "validPassengersNum",
                                              label: "待接驾数",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "startAddress",
                                              label: "上车点",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "endAddress",
                                              label: "下车点",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "intercityOrderStatus",
                                              label: "状态",
                                              "show-overflow-tooltip": "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.orderStatusList.find(
                                                              (t) =>
                                                                t.value ==
                                                                scope.row
                                                                  .intercityOrderStatus
                                                            ).label
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "orderAdditionalInformation",
                                              label: "行程附加信息",
                                              width: "120",
                                              align: "center",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "操作",
                                              width: "130",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _vm.listFind("取消接驾")
                                                        ? [
                                                            scope.row
                                                              .intercityOrderStatus ==
                                                              50 ||
                                                            scope.row
                                                              .intercityOrderStatus ==
                                                              40
                                                              ? _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      size: "small",
                                                                      sort: "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.cancelOrder(
                                                                            scope.row,
                                                                            item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "取消接驾"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.listFind("退票")
                                                        ? [
                                                            scope.row
                                                              .intercityOrderStatus >=
                                                              60 &&
                                                            scope.row
                                                              .purchaseMethod !=
                                                              3
                                                              ? _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      size: "small",
                                                                      sort: "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.cancelTicket(
                                                                            scope.row,
                                                                            "已派单"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "退票"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.listFind(
                                                        "查看乘车码"
                                                      ) &&
                                                      item.isCanPassengerCertificate
                                                        ? [
                                                            scope.row
                                                              .intercityOrderStatus !==
                                                            100
                                                              ? _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      size: "small",
                                                                      sort: "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.checkCode(
                                                                            scope.row
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "查看乘车码"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          layout: "prev, pager, next, jumper",
                          total: _vm.dispatchedTotal,
                          "page-size": _vm.dispatchedPageSize,
                          "current-page": _vm.dispatchedCurrentPage,
                        },
                        on: {
                          "current-change": _vm.dispatchedOnCurrentChange,
                          "size-change": _vm.dispatchedOnSizeChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          false
            ? _c(
                "el-tab-pane",
                { attrs: { label: "历史调度" } },
                [
                  _c(
                    "div",
                    { ref: "form", staticClass: "form-area" },
                    [
                      _c(
                        "el-form",
                        {
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            model: _vm.form,
                            "label-position": "left",
                            "label-width": "85px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "订单编号：" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入订单编号" },
                                model: {
                                  value: _vm.form.orderId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "orderId",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.orderId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "下单人：" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入下单人" },
                                model: {
                                  value: _vm.form.passengerPhone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "passengerPhone",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.passengerPhone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系电话：" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入联系人电话" },
                                model: {
                                  value: _vm.form.orderPhone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "orderPhone",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.orderPhone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "订单状态：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    "collapse-tags": true,
                                    multiple: "",
                                    clearable: "",
                                    placeholder: "请选择订单状态",
                                  },
                                  model: {
                                    value: _vm.form.orderStatus,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "orderStatus", $$v)
                                    },
                                    expression: "form.orderStatus",
                                  },
                                },
                                _vm._l(_vm.orderStatusList, function (role) {
                                  return _c("el-option", {
                                    key: role.value,
                                    attrs: {
                                      label: role.label,
                                      value: role.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "下单日期起：" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: "选择时间",
                                },
                                model: {
                                  value: _vm.form.startDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "startDate", $$v)
                                  },
                                  expression: "form.startDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "下单日期止：" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: "选择时间",
                                },
                                model: {
                                  value: _vm.form.endDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "endDate", $$v)
                                  },
                                  expression: "form.endDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "线路：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择线路",
                                  },
                                  model: {
                                    value: _vm.form.lineId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "lineId", $$v)
                                    },
                                    expression: "form.lineId",
                                  },
                                },
                                _vm._l(_vm.lineList, function (role) {
                                  return _c("el-option", {
                                    key: role.id,
                                    attrs: { label: role.name, value: role.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "业务类型：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择业务类型",
                                  },
                                  model: {
                                    value: _vm.form.businessType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "businessType", $$v)
                                    },
                                    expression: "form.businessType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "城际专车", value: 1 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "发车时间起：" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: "选择时间",
                                },
                                model: {
                                  value: _vm.form.departureStartDateTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "departureStartDateTime",
                                      $$v
                                    )
                                  },
                                  expression: "form.departureStartDateTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "发车时间止：" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: "选择时间",
                                },
                                model: {
                                  value: _vm.form.departureEndDateTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "departureEndDateTime",
                                      $$v
                                    )
                                  },
                                  expression: "form.departureEndDateTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "所属公司：" } },
                            [
                              _c("companySelect", {
                                attrs: {
                                  clearable: true,
                                  "company-tree": _vm.companyList,
                                  valuename: _vm.valueAddName,
                                  value: _vm.valueAdd,
                                },
                                on: { getValue: _vm.getAddGroupId },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSearch()
                                    },
                                  },
                                },
                                [_vm._v("查询")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onExport()
                                    },
                                  },
                                },
                                [_vm._v("导出")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("Table", {
                    attrs: {
                      "table-data": _vm.tableData,
                      "title-name": _vm.titleName,
                      operation: true,
                      "col-class-type": true,
                      "table-height": _vm.tableHeight,
                      "operation-width": "240",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        size: "small",
                                        sort: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openPopupFun(
                                            scope.scopeRow
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("订单详情")]
                                  ),
                                  _vm.listFind("改签") &&
                                  (scope.scopeRow.orderStatus == 20 ||
                                    scope.scopeRow.orderStatus == 30 ||
                                    scope.scopeRow.orderStatus == 40) &&
                                  scope.scopeRow.purchaseMethod != 3
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                            sort: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.ticketChangeFun(
                                                scope.scopeRow
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("改签")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-dropdown",
                                    { staticStyle: { "margin-left": "10px" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "el-dropdown-link" },
                                        [
                                          _vm._v(" 更多 "),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-arrow-down el-icon--right",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown",
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "small",
                                                    sort: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onHistoryDetail(
                                                        scope.scopeRow
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("调度详情")]
                                              ),
                                            ],
                                            1
                                          ),
                                          !scope.scopeRow.refundStatus &&
                                          ((scope.scopeRow.orderStatus < 70 &&
                                            scope.scopeRow.orderStatus != 10) ||
                                            scope.scopeRow.orderStatus == 80) &&
                                          scope.scopeRow.purchaseMethod != 3
                                            ? _c(
                                                "el-dropdown-item",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.listFind("退票"),
                                                      expression:
                                                        "listFind('退票')",
                                                    },
                                                  ],
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "small",
                                                        sort: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.cancelTicket(
                                                            scope.scopeRow
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("退票")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "el-dropdown-item",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "small",
                                                    sort: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addVisitInfo(
                                                        scope.scopeRow
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("回访")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2425612950
                    ),
                  }),
                  _c(
                    "div",
                    { ref: "pagination", staticClass: "pagination" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.total,
                          "page-size": _vm.form.pageSize,
                          "current-page": _vm.form.currentPage,
                        },
                        on: {
                          "size-change": _vm.onSizeChange,
                          "current-change": _vm.onCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增回访",
            visible: _vm.visitFormVisible,
            width: "70vw",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visitFormVisible = $event
            },
          },
        },
        [
          _c("add-visit-form", {
            ref: "visitForm",
            on: {
              saveOk: function ($event) {
                _vm.visitFormVisible = false
              },
              cancel: function ($event) {
                _vm.visitFormVisible = false
              },
            },
          }),
        ],
        1
      ),
      _vm.isShowTicketChange
        ? _c(
            "div",
            { staticClass: "ticket-change" },
            [
              _c("div", { staticClass: "exit", on: { click: _vm.returnFun } }, [
                _c("i", { staticClass: "iconfont its_ba_fanhui" }),
              ]),
              _c("div", { staticClass: "title" }, [_vm._v("改签")]),
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.ticketChangeActive,
                    "align-center": "",
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", {
                    attrs: {
                      title: "确认改签信息",
                      description: "请确认乘客信息是否有误",
                    },
                  }),
                  _c("el-step", {
                    attrs: {
                      title:
                        _vm.ticketChangeActive == 0 ? "改签班次" : "确认新班次",
                      description: "修改新的班次",
                    },
                  }),
                  _c("el-step", { attrs: { title: "完成", description: "" } }),
                ],
                1
              ),
              _vm.ticketChangeActive !== 2
                ? _c("phoneOrderDetial", {
                    ref: "phoneOrderDetialRef",
                    attrs: {
                      from: "ticketChange",
                      step: _vm.ticketChangeActive,
                      ticketChangeForm: _vm.ticketChangeForm,
                    },
                    on: {
                      placeOrder: _vm.placeOrder,
                      watchOrderAmount: _vm.watchOrderAmount,
                    },
                  })
                : _c("div", { staticClass: "result-box" }, [
                    _vm.isTicketChange
                      ? _c("div", { staticClass: "success-text" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/images/home/success.svg"),
                            },
                          }),
                          _c("span", [_vm._v("改签成功")]),
                        ])
                      : _c("div", { staticClass: "error-text" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/images/home/error.svg"),
                            },
                          }),
                          _c("span", [_vm._v("改签失败")]),
                        ]),
                  ]),
              _c("div", { staticClass: "button-box" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.ticketChangeActive == 0,
                        expression: "ticketChangeActive == 0",
                      },
                    ],
                  },
                  [
                    _c("el-button", { on: { click: _vm.returnFun } }, [
                      _vm._v("取消并返回"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.nextFun },
                      },
                      [_vm._v("信息确认无误")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.ticketChangeActive == 1,
                        expression: "ticketChangeActive == 1",
                      },
                    ],
                  },
                  [
                    _c("el-button", { on: { click: _vm.previousFun } }, [
                      _vm._v("上一步"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          disabled: _vm.isDisabledConfirmTicketFlag,
                        },
                        on: { click: _vm.confirmTicketChangeFun },
                      },
                      [_vm._v("确认改签")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.ticketChangeActive == 2,
                        expression: "ticketChangeActive == 2",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.returnFun("isRequest")
                          },
                        },
                      },
                      [_vm._v("返回上一页")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "配车详情",
            visible: _vm.dialogVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.onDialogClose,
          },
        },
        [
          _c(
            "div",
            [
              _c("div", [
                _vm._v(
                  " 路线：" +
                    _vm._s(
                      _vm.detailRow.startStation +
                        `-` +
                        _vm.detailRow.endStation
                    ) +
                    " "
                ),
                _c("span", {
                  staticStyle: { width: "20px", display: "inline-block" },
                }),
                _vm._v(
                  " 发车时间：" +
                    _vm._s(
                      _vm.detailRow.departureDate +
                        ` ` +
                        _vm.detailRow.departureTime
                    ) +
                    " "
                ),
                _c("span", {
                  staticStyle: { width: "20px", display: "inline-block" },
                }),
                _vm._v(
                  " 指派车辆：" +
                    _vm._s(
                      _vm.detailRow.brand +
                        `-` +
                        _vm.detailRow.licensePlateNumber
                    ) +
                    " "
                ),
              ]),
              _c(
                "el-table",
                { attrs: { data: _vm.vehicleData, stripe: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "intercityOrderId",
                      label: "订单编号",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "passengerPhone", label: "下单人" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "orderPhone", label: "联系人" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "validPassengersNum", label: "乘客数量" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "startAddress",
                      label: "上车点",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "endAddress",
                      label: "下车点",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "small",
                                  sort: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelVehicle(scope.row)
                                  },
                                },
                              },
                              [_vm._v("取消配车")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.SMStitle,
            visible: _vm.dialogVisible1,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible1 = $event
            },
            close: _vm.onDialogClose1,
          },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "body" },
              [
                _c(
                  "el-form",
                  {
                    ref: "addForm",
                    staticClass: "demo-form dialog-form",
                    attrs: {
                      model: _vm.addForm,
                      rules: _vm.rules,
                      "label-width": "70px",
                      "label-position": "left",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "原因：", prop: "name" } },
                      [
                        _c("el-input", {
                          staticClass: "textareaInputs",
                          attrs: {
                            type: "textarea",
                            maxlength: "20",
                            placeholder:
                              "填写原因时请注意，该信息将以短信的形式发送给用户。",
                          },
                          on: { input: _vm.textareaInputFun },
                          model: {
                            value: _vm.addForm.name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.SMSshow && _vm.SMStitle == "晚点短信群发"
                      ? _c("el-form-item", { attrs: { label: "短信预览：" } }, [
                          _c("div", { staticClass: "SMSpreview" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  `您预订的城际专车，时间：${
                                    _vm.SMSObj.departureDate
                                  } ${
                                    _vm.SMSObj.shiftType === 0
                                      ? _vm.SMSObj.startDepartureTime
                                      : _vm.SMSObj.startDepartureTime +
                                        "-" +
                                        _vm.SMSObj.endDepartureTime
                                  }，线路：${_vm.SMSObj.startStation}-${
                                    _vm.SMSObj.endStation
                                  }因故晚点，原因：${
                                    _vm.addForm.name
                                  }。由此对您造成的不便，敬请谅解。如有疑问，请联系司机：${
                                    _vm.SMSObj.driverName
                                  }，电话：${_vm.SMSObj.driverPhone}。`
                                ) +
                                " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible1 = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    loading: _vm.editLoading,
                  },
                  on: { click: _vm.cancelOrderSave },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.SMStitle == "晚点短信群发" ? "发送" : "保存") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.dialogVisible2
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "查看车辆位置",
                visible: _vm.dialogVisible2,
                width: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible2 = $event
                },
                close: _vm.onDialogClose2,
              },
            },
            [
              _c(
                "div",
                [
                  _c("view-map", {
                    ref: "viewMap",
                    attrs: {
                      "map-driver-obj": _vm.mapDriverObj,
                      "driver-order-id": _vm.detailRow.id,
                    },
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible2 = false
                        },
                      },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看乘车码",
            visible: _vm.showRideCode,
            width: "25%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showRideCode = $event
            },
          },
        },
        [
          _c("div", { staticClass: "currentCode" }, [
            _c("div", { staticStyle: { display: "flex" } }, [
              _c("div", { staticStyle: { "line-height": "67px" } }, [
                _vm._v("当前订单的乘车码为："),
              ]),
              _c(
                "div",
                { staticStyle: { "font-size": "40px", display: "block" } },
                [_vm._v(_vm._s(_vm.rideCode))]
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showRideCode = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.dialogVisible3
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "调度历史详情",
                visible: _vm.dialogVisible3,
                width: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible3 = $event
                },
              },
            },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.historyData, stripe: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderId",
                      label: "订单号",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "scheduleType",
                      label: "调度类型",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  scope.row.scheduleType == 1
                                    ? "派车"
                                    : "派司机"
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      441750384
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "userName",
                      label: "调度人",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "scheduleContent",
                      label: "调度内容",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "调度时间",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible3 = false
                        },
                      },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "调度改派",
            visible: _vm.dialogVisible4,
            width: "25%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible4 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "dispatch-form",
              attrs: { model: _vm.dispatchForm, "label-width": "80px" },
            },
            [
              _c("el-form-item", { attrs: { label: "线路：" } }, [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.dispatchForm.startStation +
                          `-` +
                          _vm.dispatchForm.endStation
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("el-form-item", { attrs: { label: "发车时间：" } }, [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.dispatchForm.departureDate +
                          ` ` +
                          _vm.dispatchForm.departureTime
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("el-form-item", { attrs: { label: "订单数量：" } }, [
                _vm.dispatchForm.detailVOS
                  ? _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.dispatchForm.detailVOS.length) + " "
                      ),
                    ])
                  : _c("span", [_vm._v("0")]),
              ]),
              _c("el-form-item", { attrs: { label: "乘客人数：" } }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.dispatchForm.carryCount + _vm.dispatchForm.lastCount
                    ) + "人"
                  ),
                ]),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "指派车辆：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择车辆" },
                      model: {
                        value: _vm.dispatchForm.vehicleId,
                        callback: function ($$v) {
                          _vm.$set(_vm.dispatchForm, "vehicleId", $$v)
                        },
                        expression: "dispatchForm.vehicleId",
                      },
                    },
                    _vm._l(_vm.dispatchCarList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label:
                            item.brand +
                            "-" +
                            item.licensePlateNumber +
                            "-" +
                            item.seatNumber +
                            "座",
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "指派司机：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择司机" },
                      model: {
                        value: _vm.dispatchForm.driverId,
                        callback: function ($$v) {
                          _vm.$set(_vm.dispatchForm, "driverId", $$v)
                        },
                        expression: "dispatchForm.driverId",
                      },
                    },
                    _vm._l(_vm.dispatchDriverList, function (item) {
                      return _c("el-option", {
                        key: item.driverId,
                        attrs: {
                          label: item.driverName + "-" + item.driverPhone,
                          value: item.driverId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible4 = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.confirmChangeFun },
                },
                [_vm._v("确认改派")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.operAtionShow
        ? _c("OperAtion", {
            attrs: {
              "oper-ation-data": _vm.operAtionData,
              "car-list": _vm.vehicleList,
              "popup-num": _vm.popupNum,
              "header-text": _vm.operAtionText,
            },
            on: {
              closePopupFun: _vm.closePopupFun,
              confirmFun: _vm.confirmFun,
            },
          })
        : _vm._e(),
      _vm.charterPopupShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.charterPopupShow,
                width: "800px",
                title: "退票",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.charterPopupShow = $event
                },
                close: _vm.charterPopupClosePopupFun,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { margin: "0 40px" } },
                [
                  _c("Table", {
                    attrs: {
                      "table-data": _vm.tableData2,
                      "title-name": _vm.titleName3,
                      "type-checkbox": true,
                    },
                    on: { closeFun: _vm.closeFun, "select-all": _vm.select },
                  }),
                  _c("div", { staticClass: "feeBox" }, [
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("退票手续费(%)")]),
                        _c(
                          "el-select",
                          {
                            staticClass: "charterSelect",
                            on: { change: _vm.refundChange },
                            model: {
                              value: _vm.serviceCharge,
                              callback: function ($$v) {
                                _vm.serviceCharge = $$v
                              },
                              expression: "serviceCharge",
                            },
                          },
                          _vm._l(_vm.serviceChargeList, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("退票金额")]),
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.refundTotal,
                            callback: function ($$v) {
                              _vm.refundTotal = $$v
                            },
                            expression: "refundTotal",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "reasonBox" },
                    [
                      _c("p", [_vm._v("原因")]),
                      _c("el-input", {
                        staticClass: "textareaInputs",
                        attrs: {
                          type: "textarea",
                          placeholder:
                            "填写原因时请注意，该信息将以短信的形式发送给用户。",
                        },
                        model: {
                          value: _vm.ReasonForRefund,
                          callback: function ($$v) {
                            _vm.ReasonForRefund =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "ReasonForRefund",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("p", { staticClass: "desc" }, [
                    _vm._v(
                      " 点击确认按钮提交退款申请后， 需等待当前弹窗消失， 期间请勿进行其它操作 "
                    ),
                  ]),
                  _c(
                    "el-button",
                    { on: { click: _vm.charterPopupClosePopupFun } },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.loading },
                      on: { click: _vm.charterPopupConfirmFun },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.tablePopupShow
        ? _c(
            "div",
            { staticClass: "table-Popup" },
            [
              _c("table-popup", {
                attrs: {
                  "table-data2": _vm.tableData2,
                  "title-name2": _vm.titleName2,
                  "data-popup": _vm.dataPopup,
                  "order-status-list": _vm.orderStatusList,
                },
                on: { closeFun: _vm.closeFun },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }