<template>
  <div
    ref="vehcileScheduling"
    :class="{ 'ticket-change-page': isShowTicketChange }"
    class="vehcile-scheduling"
  >
    <el-tabs
      v-show="!isShowTicketChange"
      :style="rememberTABLES == '待配车' ? 'background:#F7F8F9' : ''"
      type="border-card"
      @tab-click="tabClickFun"
    >
    <!-- 权限控制，测试环境菜单已建好：v-if="listFind('待配车')" v-if="listFind('已配车待派单')"  v-if="listFind('已派单待接乘客')"  v-if="listFind('历史调度')"-->
      <el-tab-pane v-if="false" label="待配车" class="el-tab-pane1">
        <!-- 班次树 -->
        <div class="carTree">
          <div class="carTree-btn">
            <span :class="carTreeClass[0]" @click="carTreeFun(0)">今天</span>
            <span :class="carTreeClass[1]" @click="carTreeFun(1)">明天</span>
            <span :class="carTreeClass[2]" @click="carTreeFun(2)">后天</span>
            <span :class="carTreeClass[3]" @click="carTreeFun(3)">全部</span>
          </div>
          <div class="carTree-content">
            <div v-for="(item, index) in carTreeList" :key="item.ids">
              <p
                :class="{
                  active: rememberNAME == 'line' && rememberINDEX == index,
                }"
                @click="
                  checkedNodeTree(
                    item.ids,
                    'line',
                    item.intercityOrderGroups,
                    index
                  )
                "
              >
                <i class="iconfont its_sm_luxian"></i>
                {{ item.label }}
                <span v-if="item.passengerCount != 0" class="passengerSpan">{{
                  "(" + item.passengerCount + ")"
                }}</span>
              </p>
              <span
                v-for="(jump, jndex) in item.children"
                :key="jump.ids"
                :class="{
                  active:
                    rememberNAME == 'time' &&
                    rememberJNDEX == jndex &&
                    rememberINDEX == index,
                }"
                @click="
                  checkedNodeTree(
                    jump.ids,
                    'time',
                    jump.intercityOrderGroups,
                    index,
                    jndex
                  )
                "
              >
                {{ jump.label }}
                <span v-if="jump.passengerCount != 0" class="passengerSpan">{{
                  "(" + jump.passengerCount + ")"
                }}</span>
              </span>
            </div>
            <!-- <el-tree
              :expand-on-click-node="false"
              :default-expand-all="true"
              :data="carTreeList"
              :props="CARdefaultProps"
              :render-content="renderContent"
            ></el-tree>-->
          </div>
        </div>
        <div class="carContent">
          <el-row :gutter="20">
            <!-- <el-col :span="5">
            <div>
              <el-tree
                :data="companyList"
                :props="defaultProps"
                @node-click="handleNodeClick"
                node-key="id"
                default-expand-all
                :expand-on-click-node="false"
              ></el-tree>
            </div>
            </el-col>-->
            <el-col :span="24">
              <div ref="form" style="background: #ffffff" class="form-area">
                <el-form
                  :inline="true"
                  :model="searchForm"
                  label-position="left"
                  label-width="74px"
                  class="demo-form-inline"
                >
                  <!-- <el-form-item label="线路：">
                    <el-select v-model="searchForm.lineId" clearable placeholder="请选择线路">
                      <el-option
                        v-for="role in lineList"
                        :label="role.name"
                        :value="role.id"
                        :key="role.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="发车日期：">
                    <el-date-picker
                      v-model="searchForm.startDate"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="发车日期"
                    ></el-date-picker>
                  </el-form-item>-->
                  <el-form-item>
                    <!-- <el-button
                      type="primary"
                      size="small"
                      @click="()=>{this.cardeployShow==true?getDataList(1):null}"
                    >查询</el-button>-->
                    <template v-if="listFind('批量配车')">
                      <el-button
                        v-show="cardeployShow && batchCar"
                        type="primary"
                        size="small"
                        @click="
                          () => {
                            this.cardMaskShow = true;
                            this.marskArray = [];
                            this.cardeployShow = false;
                          }
                        "
                        >批量配车</el-button
                      >
                    </template>
                    <el-button
                      v-show="!cardeployShow"
                      type="primary"
                      size="small"
                      @click="
                        () => {
                          this.cardMaskShow = false;
                          this.cardeployShow = true;
                        }
                      "
                      >取消</el-button
                    >
                    <el-button
                      v-show="!cardeployShow"
                      type="primary"
                      size="small"
                      @click="cardeployFun()"
                      >确认配车</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
              <div ref="functionButtons" class="function-buttons contentGrid">
                <div v-show="unData.length == 0" class="contentGridnoData">
                  暂无数据
                </div>
                <div
                  v-for="strip in unData"
                  v-show="unData.length > 0"
                  :key="strip.departureDate"
                  class="card-content"
                >
                  <div class="card-label">
                    <p>
                      <span class="Date">{{ strip.departureDate }}</span>
                      <span class="total">
                        <div>乘客数：</div>
                        {{ strip.passengerCount }}
                      </span>
                      <span class="total">
                        <div>订单数：</div>
                        {{ strip.intercityOrderTotal }}
                      </span>
                      <span class="total">
                        <div>班次：</div>
                        {{ strip.shiftIntercityCount }}
                      </span>
                    </p>
                  </div>
                  <div class="gridContent">
                    <div
                      v-for="(item, index) in strip.intercityOrders"
                      :key="item.id"
                      class="card"
                    >
                      <div
                        v-show="cardMaskShow"
                        :class="
                          marskArray.indexOf(index) == -1
                            ? 'mask'
                            : 'active-mask' + ' mask'
                        "
                        @click="maskFun(index, item)"
                      ></div>
                      <div class="line">
                        {{ item.startStation + `-` + item.endStation }}
                      </div>
                      <div class="time">
                        {{ item.departureDate + ` ` + item.departureTime }}
                      </div>
                      <el-divider></el-divider>
                      <div>
                        <span class="colorSpan">订单编号：</span>
                        {{ item.id }}
                      </div>
                      <div>
                        <span class="colorSpan">下单人：</span>
                        {{ item.passengerPhone }}
                      </div>
                      <div>
                        <span class="colorSpan">联系人：</span>
                        {{ item.orderPhone }}
                      </div>
                      <div>
                        <span class="colorSpan">乘客人数：</span>
                        {{ item.validPassengersNum }}人
                      </div>
                      <div>
                        <span class="colorSpan">上车点：</span>
                        {{ item.startAddress }}
                      </div>
                      <div>
                        <span class="colorSpan">下车点：</span>
                        {{ item.endAddress }}
                      </div>
                      <div>
                        <el-select
                          v-model="item.vehicleId"
                          clearable
                          placeholder="请选择车牌号"
                          style="margin-top: 10px; margin-bottom: 10px"
                          @visible-change="visibleChangeCarId($event, item)"
                        >
                          <el-option
                            v-for="e in vehicleList"
                            :key="e.id"
                            :label="
                              e.brand +
                              '-' +
                              e.licensePlateNumber +
                              '-' +
                              e.seatNumber +
                              '座'
                            "
                            :value="e.id"
                          ></el-option>
                        </el-select>
                        <el-button
                          type="primary"
                          size="small"
                          @click="onVehicle(item)"
                          >确认</el-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="false" label="已配车，待派单">
        <el-row :gutter="20">
          <!-- <el-col :span="5">
            <div>
              <el-tree
                :data="companyList"
                :props="defaultProps"
                @node-click="handleNodeClick"
                node-key="id"
                default-expand-all
                :expand-on-click-node="false"
              ></el-tree>
            </div>
          </el-col>-->
          <el-col :span="24">
            <div ref="form" class="form-area">
              <el-form
                :inline="true"
                :model="searchForm"
                label-position="left"
                label-width="80px"
                class="demo-form-inline"
              >
                <el-form-item label="线路：">
                  <el-select
                    v-model="searchForm.lineId"
                    clearable
                    placeholder="请选择线路"
                  >
                    <el-option
                      v-for="role in lineList"
                      :key="role.id"
                      :label="role.name"
                      :value="role.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="发车日期：">
                  <el-date-picker
                    v-model="searchForm.startDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="发车日期"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="small" @click="getDataList(2)"
                    >查询</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
            <div
              ref="functionButtons"
              class="function-buttons contentGrid contentGrid2"
            >
              <div v-for="item in ingData" :key="item.id" class="card">
                <div class="line">
                  {{ item.startStation + `-` + item.endStation }}
                </div>
                <div class="time">
                  {{ item.departureDate + ` ` + item.departureTime }}
                </div>
                <el-divider></el-divider>
                <div>配车人数：{{ item.passengerCount }}人</div>
                <div>未配车人数：{{ item.surplusPassengerCount }}人</div>
                <div>
                  指派车辆：{{ item.brand + `-` + item.licensePlateNumber }}
                </div>
                <div>
                  指派司机：
                  <el-select
                    v-model="item.driverId"
                    clearable
                    placeholder="请选择司机"
                    @visible-change="visibleChange($event, item)"
                  >
                    <el-option
                      v-for="e in driverList"
                      :key="e.id"
                      :label="e.driverName + '-' + e.driverPhone"
                      :value="e.driverId"
                    ></el-option>
                  </el-select>
                </div>
                <div class="btn">
                  <el-button type="primary" size="small" @click="onDetail(item)"
                    >配车详情</el-button
                  >
                  <el-button
                    v-if="listFind('派单')"
                    type="primary"
                    size="small"
                    @click="onDriver(item)"
                    >确认派单</el-button
                  >
                  <el-button
                    type="primary"
                    size="small"
                    @click="onDeleteOrderFun(item)"
                    >删除</el-button
                  >
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="已派单，待接乘客">
        <el-row :gutter="20">
          <!-- <el-col :span="5">
            <div>
              <el-tree
                :data="companyList"
                :props="defaultProps"
                @node-click="handleNodeClick"
                node-key="id"
                default-expand-all
                :expand-on-click-node="false"
              ></el-tree>
            </div>
          </el-col>-->
          <el-col :span="24">
            <div ref="form" class="form-area">
              <el-form
                :inline="true"
                :model="searchForm"
                class="demo-form-inline"
                label-position="left"
                label-width="80px"
              >
                <el-form-item label="线路：">
                  <el-select
                    v-model="searchForm.lineId"
                    clearable
                    placeholder="请选择线路"
                  >
                    <el-option
                      v-for="role in lineList"
                      :key="role.id"
                      :label="role.name"
                      :value="role.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="发车日期：">
                  <el-date-picker
                    v-model="searchForm.startDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="发车日期"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="small" @click="getDataList(3)"
                    >查询</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
            <div
              ref="functionButtons"
              class="function-buttons contentGrid tableGrid"
            >
              <div v-for="item in dData" :key="item.id" class="card">
                <el-row :gutter="20">
                  <el-col
                    :span="6"
                    style="padding-left: 24px; padding-top: 10px"
                  >
                    <div class="line">{{ item.lineName }}</div>
                    <div class="time">                     
                      {{computedShiftIntercityTime(item)}}
                    </div>
                    <el-divider></el-divider>
                    <div>
                      指派车辆：{{
                        item.brand +
                        `-` +
                        item.licensePlateNumber +
                        `-` +
                        item.seatNumber +
                        `座`
                      }}
                    </div>
                    <div>指派司机：{{ item.driverName }}</div>
                    <div>联系电话：{{ item.driverPhone }}</div>
                    <div>已接人数：{{ item.carryCount }}人</div>
                    <div>待接人数：{{ item.lastCount }}人</div>
                    <el-button type="primary" size="small" @click="SMSFun(item)"
                      >短信群发</el-button
                    >
                    <el-button
                      v-if="listFind('查看车辆位置')"
                      type="primary"
                      size="small"
                      @click="onCheck(item)"
                      >查看车辆位置</el-button
                    >
                    <!-- v7.1 -->
                    <!-- <el-button
                      type="primary"
                      size="small"
                      :disabled="!!item.carryCount"
                      @click="changeDispatchFun(item)"
                      >调度改派</el-button
                    > -->
                  </el-col>
                  <el-col :span="18">
                    <el-table :data="item.detailVOS" stripe height="30vh">
                      <el-table-column
                        prop="passengerPhone"
                        label="下单人"
                        width="120"
                        show-overflow-tooltip
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        prop="orderPhone"
                        label="联系人"
                        width="120"
                        show-overflow-tooltip
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        align="center"
                        prop="validPassengersNum"
                        label="待接驾数"
                        show-overflow-tooltip
                      ></el-table-column>
                      <el-table-column
                        align="center"
                        prop="startAddress"
                        label="上车点"
                        show-overflow-tooltip
                      ></el-table-column>
                      <el-table-column
                        align="center"
                        prop="endAddress"
                        label="下车点"
                        show-overflow-tooltip
                      ></el-table-column>
                      <el-table-column
                        align="center"
                        prop="intercityOrderStatus"
                        label="状态"
                        show-overflow-tooltip
                      >
                        <template slot-scope="scope">
                          {{
                            orderStatusList.find(
                              (t) => t.value == scope.row.intercityOrderStatus
                            ).label
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="orderAdditionalInformation"
                        label="行程附加信息"
                        width="120"
                        align="center"
                        show-overflow-tooltip
                      ></el-table-column>
                      <el-table-column label="操作" width="130" align="center">
                        <template slot-scope="scope">
                          <template v-if="listFind('取消接驾')">
                            <el-button
                              v-if="
                                scope.row.intercityOrderStatus == 50 ||
                                scope.row.intercityOrderStatus == 40
                              "
                              type="text"
                              size="small"
                              sort="primary"
                              @click="cancelOrder(scope.row, item)"
                              >取消接驾</el-button
                            >
                          </template>
                          <template v-if="listFind('退票')">
                            <el-button
                              v-if="
                                scope.row.intercityOrderStatus >= 60 &&
                                scope.row.purchaseMethod != 3
                              "
                              type="text"
                              size="small"
                              sort="primary"
                              @click="cancelTicket(scope.row, '已派单')"
                              >退票</el-button
                            >
                          </template>
                          <template
                            v-if="
                              listFind('查看乘车码') &&
                              item.isCanPassengerCertificate
                            "
                          >
                            <el-button
                              v-if="scope.row.intercityOrderStatus !== 100"
                              type="text"
                              size="small"
                              sort="primary"
                              @click="checkCode(scope.row)"
                              >查看乘车码</el-button
                            >
                          </template>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-col>
                </el-row>
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next, jumper"
              :total="dispatchedTotal"
              :page-size="dispatchedPageSize"
              :current-page="dispatchedCurrentPage"
              @current-change="dispatchedOnCurrentChange"
              @size-change="dispatchedOnSizeChange"
            ></el-pagination>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane v-if="false" label="历史调度">
        <div ref="form" class="form-area">
          <el-form
            :inline="true"
            :model="form"
            class="demo-form-inline"
            label-position="left"
            label-width="85px"
          >
            <el-form-item label="订单编号：">
              <el-input
                v-model.trim="form.orderId"
                placeholder="请输入订单编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="下单人：">
              <el-input
                v-model.trim="form.passengerPhone"
                placeholder="请输入下单人"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话：">
              <el-input
                v-model.trim="form.orderPhone"
                placeholder="请输入联系人电话"
              ></el-input>
            </el-form-item>
            <el-form-item label="订单状态：">
              <el-select
                v-model="form.orderStatus"
                :collapse-tags="true"
                multiple
                clearable
                placeholder="请选择订单状态"
              >
                <el-option
                  v-for="role in orderStatusList"
                  :key="role.value"
                  :label="role.label"
                  :value="role.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="下单日期起：">
              <el-date-picker
                v-model="form.startDate"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择时间"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="下单日期止：">
              <el-date-picker
                v-model="form.endDate"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择时间"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="线路：">
              <el-select
                v-model="form.lineId"
                clearable
                placeholder="请选择线路"
              >
                <el-option
                  v-for="role in lineList"
                  :key="role.id"
                  :label="role.name"
                  :value="role.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="业务类型：">
              <el-select
                v-model="form.businessType"
                clearable
                placeholder="请选择业务类型"
              >
                <el-option label="城际专车" :value="1"></el-option>
                <!-- <el-option label="汽车订票" :value="1"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item label="发车时间起：">
              <el-date-picker
                v-model="form.departureStartDateTime"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择时间"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="发车时间止：">
              <el-date-picker
                v-model="form.departureEndDateTime"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择时间"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="所属公司：">
              <companySelect
                :clearable="true"
                :company-tree="companyList"
                :valuename="valueAddName"
                :value="valueAdd"
                @getValue="getAddGroupId"
              ></companySelect>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="onSearch()"
                >查询</el-button
              >
              <el-button type="primary" size="small" @click="onExport()"
                >导出</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <Table
          :table-data="tableData"
          :title-name="titleName"
          :operation="true"
          :col-class-type="true"
          :table-height="tableHeight"
          operation-width="240"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                type="text"
                size="small"
                sort="primary"
                @click="openPopupFun(scope.scopeRow)"
                >订单详情</el-button
              >
              <!-- v7.1 -->
              <el-button
                v-if="
                  listFind('改签') &&
                  (scope.scopeRow.orderStatus == 20 ||
                    scope.scopeRow.orderStatus == 30 ||
                    scope.scopeRow.orderStatus == 40) &&
                  scope.scopeRow.purchaseMethod != 3
                "
                type="text"
                size="small"
                sort="primary"
                @click="ticketChangeFun(scope.scopeRow)"
                >改签</el-button
              >

              <el-dropdown style="margin-left: 10px">
                <span class="el-dropdown-link">
                  更多
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-button
                      type="text"
                      size="small"
                      sort="primary"
                      @click="onHistoryDetail(scope.scopeRow)"
                      >调度详情</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="
                      !scope.scopeRow.refundStatus &&
                      ((scope.scopeRow.orderStatus < 70 &&
                        scope.scopeRow.orderStatus != 10) ||
                        scope.scopeRow.orderStatus == 80) &&
                      scope.scopeRow.purchaseMethod != 3
                    "
                    v-show="listFind('退票')"
                  >
                    <el-button
                      type="text"
                      size="small"
                      sort="primary"
                      @click="cancelTicket(scope.scopeRow)"
                      >退票</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <!-- 7.2 -->
                    <el-button
                      type="text"
                      size="small"
                      sort="primary"
                      @click="addVisitInfo(scope.scopeRow)"
                      >回访</el-button
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </Table>
        <div ref="pagination" class="pagination">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-size="form.pageSize"
            :current-page="form.currentPage"
            @size-change="onSizeChange"
            @current-change="onCurrentChange"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 7.2 新增回访填写表单 -->
    <el-dialog title="新增回访" :visible.sync="visitFormVisible" width="70vw">
      <add-visit-form
        ref="visitForm"
        @saveOk="visitFormVisible = false"
        @cancel="visitFormVisible = false"
      />
    </el-dialog>
    <!-- v7.1改签页面 -->
    <div v-if="isShowTicketChange" class="ticket-change">
      <div class="exit" @click="returnFun">
        <i class="iconfont its_ba_fanhui"></i>
      </div>
      <div class="title">改签</div>
      <el-steps
        :active="ticketChangeActive"
        align-center
        finish-status="success"
      >
        <el-step
          title="确认改签信息"
          description="请确认乘客信息是否有误"
        ></el-step>
        <el-step
          :title="ticketChangeActive == 0 ? '改签班次' : '确认新班次'"
          description="修改新的班次"
        ></el-step>
        <el-step title="完成" description></el-step>
      </el-steps>
      <phoneOrderDetial
        ref="phoneOrderDetialRef"
        v-if="ticketChangeActive !== 2"
        from="ticketChange"
        :step="ticketChangeActive"
        :ticketChangeForm="ticketChangeForm"
        @placeOrder="placeOrder"
        @watchOrderAmount="watchOrderAmount"
      ></phoneOrderDetial>
      <div v-else class="result-box">
        <div v-if="isTicketChange" class="success-text">
          <img src="../../../assets/images/home/success.svg" />
          <span>改签成功</span>
        </div>
        <div v-else class="error-text">
          <img src="../../../assets/images/home/error.svg" />
          <span>改签失败</span>
        </div>
      </div>
      <div class="button-box">
        <div v-show="ticketChangeActive == 0">
          <el-button @click="returnFun">取消并返回</el-button>
          <el-button type="primary" @click="nextFun">信息确认无误</el-button>
        </div>
        <div v-show="ticketChangeActive == 1">
          <el-button @click="previousFun">上一步</el-button>
          <el-button
            type="primary"
            :disabled="isDisabledConfirmTicketFlag"
            @click="confirmTicketChangeFun"
            >确认改签</el-button
          >
        </div>
        <div v-show="ticketChangeActive == 2">
          <el-button type="primary" @click="returnFun('isRequest')"
            >返回上一页</el-button
          >
        </div>
      </div>
    </div>

    <el-dialog
      title="配车详情"
      :visible.sync="dialogVisible"
      width="60%"
      @close="onDialogClose"
    >
      <div>
        <div>
          路线：{{ detailRow.startStation + `-` + detailRow.endStation }}
          <span style="width: 20px; display: inline-block"></span>
          发车时间：{{
            detailRow.departureDate + ` ` + detailRow.departureTime
          }}
          <span style="width: 20px; display: inline-block"></span>
          指派车辆：{{ detailRow.brand + `-` + detailRow.licensePlateNumber }}
        </div>
        <el-table :data="vehicleData" stripe>
          <el-table-column
            prop="intercityOrderId"
            label="订单编号"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="passengerPhone"
            label="下单人"
          ></el-table-column>
          <el-table-column prop="orderPhone" label="联系人"></el-table-column>
          <el-table-column
            prop="validPassengersNum"
            label="乘客数量"
          ></el-table-column>
          <el-table-column
            prop="startAddress"
            label="上车点"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="endAddress"
            label="下车点"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                sort="primary"
                @click="cancelVehicle(scope.row)"
                >取消配车</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="SMStitle"
      :visible.sync="dialogVisible1"
      width="500px"
      @close="onDialogClose1"
    >
      <div>
        <div class="body">
          <el-form
            ref="addForm"
            :model="addForm"
            :rules="rules"
            label-width="70px"
            label-position="left"
            class="demo-form dialog-form"
          >
            <el-form-item label="原因：" prop="name">
              <el-input
                v-model.trim="addForm.name"
                type="textarea"
                maxlength="20"
                placeholder="填写原因时请注意，该信息将以短信的形式发送给用户。"
                class="textareaInputs"
                @input="textareaInputFun"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="SMSshow && SMStitle == '晚点短信群发'"
              label="短信预览："
            >
              <div class="SMSpreview">
                {{
                  `您预订的城际专车，时间：${SMSObj.departureDate}
                ${SMSObj.shiftType===0 ? SMSObj.startDepartureTime : SMSObj.startDepartureTime+'-'+SMSObj.endDepartureTime}，线路：${SMSObj.startStation}-${SMSObj.endStation}因故晚点，原因：${addForm.name}。由此对您造成的不便，敬请谅解。如有疑问，请联系司机：${SMSObj.driverName}，电话：${SMSObj.driverPhone}。`
                }}
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible1 = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="cancelOrderSave"
        >
          {{ SMStitle == "晚点短信群发" ? "发送" : "保存" }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-if="dialogVisible2"
      title="查看车辆位置"
      :visible.sync="dialogVisible2"
      width="60%"
      @close="onDialogClose2"
    >
      <div>
        <view-map
          ref="viewMap"
          :map-driver-obj="mapDriverObj"
          :driver-order-id="detailRow.id"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible2 = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="查看乘车码" :visible.sync="showRideCode" width="25%">
      <div class="currentCode">
        <div style="display: flex">
          <div style="line-height: 67px">当前订单的乘车码为：</div>
          <div style="font-size: 40px; display: block">{{ rideCode }}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="showRideCode = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-if="dialogVisible3"
      title="调度历史详情"
      :visible.sync="dialogVisible3"
      width="60%"
    >
      <el-table :data="historyData" stripe>
        <el-table-column
          prop="orderId"
          label="订单号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="scheduleType"
          label="调度类型"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{
            scope.row.scheduleType == 1 ? "派车" : "派司机"
          }}</template>
        </el-table-column>
        <el-table-column
          prop="userName"
          label="调度人"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="scheduleContent"
          label="调度内容"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="调度时间"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible3 = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <!-- v7.1 调度改派 -->
    <el-dialog title="调度改派" :visible.sync="dialogVisible4" width="25%">
      <el-form
        ref="form"
        :model="dispatchForm"
        label-width="80px"
        class="dispatch-form"
      >
        <el-form-item label="线路：">
          <span>
            {{ dispatchForm.startStation + `-` + dispatchForm.endStation }}
          </span>
        </el-form-item>
        <el-form-item label="发车时间：">
          <span>
            {{ dispatchForm.departureDate + ` ` + dispatchForm.departureTime }}
          </span>
        </el-form-item>
        <el-form-item label="订单数量：">
          <span v-if="dispatchForm.detailVOS">
            {{ dispatchForm.detailVOS.length }}
          </span>
          <span v-else>0</span>
        </el-form-item>
        <el-form-item label="乘客人数：">
          <span>{{ dispatchForm.carryCount + dispatchForm.lastCount }}人</span>
        </el-form-item>
        <el-form-item label="指派车辆：">
          <el-select
            v-model="dispatchForm.vehicleId"
            clearable
            placeholder="请选择车辆"
          >
            <el-option
              v-for="item in dispatchCarList"
              :key="item.id"
              :label="
                item.brand +
                '-' +
                item.licensePlateNumber +
                '-' +
                item.seatNumber +
                '座'
              "
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指派司机：">
          <el-select
            v-model="dispatchForm.driverId"
            clearable
            placeholder="请选择司机"
          >
            <el-option
              v-for="item in dispatchDriverList"
              :key="item.driverId"
              :label="item.driverName + '-' + item.driverPhone"
              :value="item.driverId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible4 = false">取消</el-button>
        <el-button type="primary" size="small" @click="confirmChangeFun"
          >确认改派</el-button
        >
      </span>
    </el-dialog>
    <OperAtion
      v-if="operAtionShow"
      :oper-ation-data="operAtionData"
      :car-list="vehicleList"
      :popup-num="popupNum"
      :header-text="operAtionText"
      @closePopupFun="closePopupFun"
      @confirmFun="confirmFun"
    />
    <el-dialog
      v-if="charterPopupShow"
      :visible.sync="charterPopupShow"
      width="800px"
      title="退票"
      @close="charterPopupClosePopupFun"
    >
      <div style="margin: 0 40px">
        <Table
          :table-data="tableData2"
          :title-name="titleName3"
          :type-checkbox="true"
          @closeFun="closeFun"
          @select-all="select"
        />
        <div class="feeBox">
          <div>
            <p>退票手续费(%)</p>
            <el-select
              v-model="serviceCharge"
              class="charterSelect"
              @change="refundChange"
            >
              <el-option
                v-for="item in serviceChargeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div>
            <p>退票金额</p>
            <el-input v-model="refundTotal" disabled />
          </div>
        </div>
        <div class="reasonBox">
          <p>原因</p>
          <el-input
            v-model.trim="ReasonForRefund"
            class="textareaInputs"
            type="textarea"
            placeholder="填写原因时请注意，该信息将以短信的形式发送给用户。"
          ></el-input>
        </div>
      </div>
      <div slot="footer" class="footer">
        <p class="desc">
          点击确认按钮提交退款申请后， 需等待当前弹窗消失， 期间请勿进行其它操作
        </p>
        <el-button @click="charterPopupClosePopupFun">取消</el-button>
        <el-button
          type="primary"
          :disabled="loading"
          @click="charterPopupConfirmFun"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <div v-if="tablePopupShow" class="table-Popup">
      <table-popup
        :table-data2="tableData2"
        :title-name2="titleName2"
        :data-popup="dataPopup"
        :order-status-list="orderStatusList"
        @closeFun="closeFun"
      />
    </div>
  </div>
</template>

<script>
import { lastTime } from "@/tools/getTime.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
import phoneOrderDetial from "./phoneOrderDetial.vue";
import {
  getAllLine,
  getAllVehicleList,
  getAllDriverList,
  driverIntercityAPI,
  updateDispatchOfDriverOrCarAPI, // 调度改派v7.1
  getOrderInfoAPI, //获取订单详细信息
  queryUnscheduleList,
  querySchedulingList,
  querySchedulingDetail,
  queryScheduledList,
  queryScheduleHistoryList,
  queryScheduleHistoryDetail,
  cancelVehicle,
  cancelOrder,
  cancelTicket,
  scheduleVehicle,
  scheduleDriver,
  deleteDriverOrder,
  carTreeAPI,
  lateNoticeAPI,
  getCompanyTree,
  checkCodeAPI,
  referOrderDetialAPI,
  historyExportAPI,
} from "@/api/lib/api.js";
import viewMap from "./viewMap/viewMap.vue";
import TablePopup from "@/components/charterPopup/tablePopup.vue";
import addVisitForm from "./addVisitForm/addVisitForm.vue";
export default {
  components: {
    companySelect,
    viewMap,
    TablePopup,
    phoneOrderDetial,
    addVisitForm,
  },
  data() {
    return {
      dispatchedPageSize: 10,
      dispatchedCurrentPage: 1,
      dispatchedTotal: 0,
      refundTotal: 0,
      tableData2: [],
      titleName2: [
        {
          title: "票号",
          props: "id",
          width: "200",
        },
        {
          title: "乘客类型",
          props: "segment",
          SpecialJudgment: (res) => {
            return res == 0 ? "儿童" : "成人";
          },
        },
        {
          title: "乘客姓名",
          props: "passengerName",
        },
        {
          title: "手机号码",
          width: 120,
          props: "passengerPhone",
        },
        {
          title: "身份证号码",
          props: "idCardNo",
          width: 120,
          SpecialJudgment: (res) => {
            return res == "匿名用户"
              ? res
              : this.decryptDes(res).replace(
                  /^(.{4})(?:\d+)(.{3})$/,
                  "$1******$2"
                );
          },
        },
        {
          title: "是否携童",
          props: "carryingChildren",
          SpecialJudgment: (res) => (res ? "是" : "否"),
        },
        {
          title: "票价",
          props: "ticketPricesPer",
        },
        {
          title: "服务费",
          props: "servicePricesPer",
        },
        {
          title: "应付金额",
          props: "payablePrices",
        },
        {
          title: "优惠金额",
          props: "preferentialPrices",
        },
        {
          title: "实付金额",
          props: "totalPrices",
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            return this.childOrderStatusList.find((t) => t.value == res).label;
          },
        },
        {
          title: "退(改)优惠金额",
          props: "refundTicketPreferentialPrices",
          width: 130,
        },
        {
          title: "退票金额",
          props: "refundTicketPrices",
        },
        {
          title: "退票手续费",
          props: "refundTicketServicePrices",
          width: 120,
        },
        {
          title: "退票时间",
          props: "refundTime",
        },
        {
          title: "改签退回金额",
          props: "changedRefundPrices",
          width: 120,
        },
        {
          title: "改签手续费",
          props: "changedServicePrices",
          width: 120,
        },
        {
          title: "改签时间",
          props: "changedTime",
        },
      ],
      titleName3: [
        {
          title: "票号",
          props: "id",
          width: "200",
        },
        {
          title: "乘客类型",
          props: "segment",
          SpecialJudgment: (res) => {
            return res == 0 ? "儿童" : "成人";
          },
        },
        {
          title: "乘客姓名",
          props: "passengerName",
        },
        {
          title: "身份证号码",
          width: 120,
          props: "idCardNo",
          SpecialJudgment: (res) => {
            return res == "匿名用户"
              ? res
              : this.decryptDes(res).replace(
                  /^(.{4})(?:\d+)(.{3})$/,
                  "$1******$2"
                );
          },
        },
        {
          title: "是否携童",
          props: "carryingChildren",
          SpecialJudgment: (res) => (res ? "是" : "否"),
        },
        {
          title: "票价",
          props: "ticketPricesPer",
        },
        {
          title: "服务费",
          props: "servicePricesPer",
        },
        {
          title: "支付金额",
          props: "totalPrices",
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            return this.childOrderStatusList.find((t) => t.value == res).label;
          },
        },
      ],
      dataPopup: {},
      ticketPrice: 0,
      childOrderStatusList: [
        { value: 20, label: "已支付" },
        { value: 90, label: "用户已退票" },
        { value: 95, label: "退款中" },
        { value: 100, label: "后台已退票" },
        { value: 130, label: "已改签" },
        { value: null, label: "" },
      ],
      tablePopupShow: false,
      serviceCharge: 0,
      serviceChargeList: [{ label: "0", value: 0 }],
      cancelTicketObj: {},
      cancelTicketName: "",
      ReasonForRefund: "",
      charterPopupShow: false,
      mapDriverObj: {},
      rememberTABLES: "待配车",
      rememberNAME: "",
      rememberINDEX: "",
      rememberJNDEX: "",
      rememberNUM: 0,
      valueAdd: null,
      valueAddName: null,
      batchCar: true,
      activeChecked: null,
      carTreeList: [],
      datacarTree: [],
      carTreeClass: [
        "carTree-li carTree-Active",
        "carTree-li",
        "carTree-li",
        "carTree-li",
      ],
      popupNum: "",
      operAtionText: "", //  弹窗标题
      operAtionShow: false, //  公用操作弹窗的开关
      cardeployShow: true,
      marskArray: [],
      showRideCode: false,
      rideCode: null,
      cardMaskShow: false,
      titleName: [
        {
          title: "订单编号",
          props: "orderId",
          width: "200",
        },
        {
          title: "下单人",
          props: "passengerPhone",
        },
        {
          title: "联系电话",
          width: 120,
          props: "orderPhone",
        },
        {
          title: "乘客数量",
          props: "passengerCount",
        },
        {
          title: "有效乘车人数",
          width: 120,
          props: "validPassengersNum",
        },
        {
          title: "携童数",
          props: "carryingChildrenNum",
        },
        {
          title: "票价",
          props: "ticketPricesPer",
        },
        {
          title: "服务费",
          props: "servicePricesPer",
        },
        {
          title: "应付金额",
          props: "payablePrices",
        },
        {
          title: "优惠金额",
          props: "preferentialPrices",
        },
        {
          title: "实付金额",
          props: "totalPrices",
        },
        {
          title: "购票方式",
          props: "purchaseMethod",
          SpecialJudgment: (res) => {
            return res == 0
              ? "乘客购票"
              : res == 1
              ? "后台购票"
              : res == 2
              ? "快客码购票"
              : res == 3
              ? "Api购票"
              : "预留";
          },
        },
        {
          title: "所属公司",
          props: "companyName",
          width: 120,
        },
        {
          title: "线路",
          width: 100,
          SpecialJudgment: (res) => {
            return res.startStation + `-` + res.endStation;
          },
        },
        {
          title: "发车时间",
          width: 140,
          SpecialJudgment: (res) => {
            return res.departureDate + ` ` + res.departureTime;
          },
        },
        {
          title: "上车点",
          props: "startAddress",
        },
        {
          title: "下车点",
          props: "endAddress",
        },
        {
          title: "下单时间",
          width: 170,
          props: "createTime",
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            return this.orderStatusTextFun(
              this.orderStatusList.find((t) => t.value == res).label
            );
          },
        },
        {
          title: "退(改)优惠金额",
          props: "refundTicketPreferentialPrices",
          width: 130,
        },
        {
          title: "退票金额",
          props: "refundFee",
        },
        {
          title: "退票手续费",
          width: "100",
          props: "poundage",
        },
        {
          title: "最后退票时间",
          props: "refundTime",
          width: 110,
        },
        {
          title: "改签退回金额",
          props: "changedRefundPrices",
          width: 120,
        },
        {
          title: "改签手续费",
          props: "changedServicePrices",
          width: 100,
        },
        {
          title: "最后改签时间",
          props: "changedTime",
          width: 120,
        },
      ],
      tableHeight: 0,
      map: null,
      tableData: [],
      unData: [],
      ingData: [],
      dData: [],
      vehicleData: [],
      historyData: [],
      detailRow: {},
      lineData: {},
      defaultProps: {
        children: "children",
        label: "name",
      },
      form: {
        orderId: null,
        passengerPhone: null,
        orderPone: null,
        orderStatus: null,
        lineId: null,
        startDate: null,
        endDate: null,
        businessType: null,
        currentPage: 1,
        pageSize: 10,
        departureStartDateTime: "",
        departureEndDateTime: "",
      },
      searchForm: {
        startDate: null,
        lineId: null,
      },
      total: 0,
      addForm: {
        name: "",
        intercityOrderId: "",
      },
      rules: {
        ticketPrice: [
          { required: true, message: "请输入必填项", trigger: "blur" },
        ],
      },
      visitFormVisible: false,
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false, //v7.1调度改派弹窗
      dispatchForm: {
        startStation: "",
        endStation: "",
        departureDate: "",
        departureTime: "",
        detailVOS: "",
        vehicleId: "",
        oldVehicleId: "",
        driverId: "",
        oldDriverId: "",
      },
      dispatchCarList: [], //指派车辆
      dispatchDriverList: [], //指派司机
      ticketChangeActive: 0, //改签步骤条
      ticketChangeForm: {},
      isShowTicketChange: false, //是否显示改签页面
      isTicketChange: false, //改签结果
      title: "",
      lineList: [],
      companyList: [],
      vehicleList: [],
      driverList: [],
      orderStatusList: [
        { value: 10, label: "待支付" },
        { value: 20, label: "待配车" },
        { value: 30, label: "已配车（待派单）" },
        { value: 40, label: "已派单（待接驾）" },
        { value: 50, label: "司机申请取消接驾" },
        { value: 60, label: "已接驾" },
        { value: 70, label: "已完成" },
        { value: 80, label: "取消接驾" },
        { value: 110, label: "订单取消" },
        { value: 120, label: "已退票" },
        { value: 130, label: "已改签" },
      ],
      shiftIntercityId: null,
      editLoading: false,
      showDeatil: false,
      userId: null,
      operAtionData: undefined,
      timeNumber: 0,
      timeoutOnofft: false,
      SMStitle: "",
      SMSshow: false,
      SMSObj: {},
      isDisabledConfirmTicketFlag: true,
      children: 0,
      loading: false,
    };
  },
  watch: {
    $route(to, from) {
      document.getElementById("tab-0").click();
      // this.search();
    },
    dialogVisible1: {
      handler(val) {
        if (val == false) {
          this.SMSshow = false;
        }
      },
    },
    "dispatchForm.vehicleId"(val) {
      this.dispatchForm.driverId = "";
      this.dispatchDriverList = [];
      if (val) {
        this.getDriverIntercityFun(val);
      }
    },
  },
  methods: {
    computedShiftIntercityTime (item) {
      let str = item.departureDate;
      if (item.shiftType === 1) {
        str += ` ${item.startDepartureTime}-${item.endDepartureTime}`
      } else {
        str += ` ${item.startDepartureTime}`
      }
      return str
    },
    decryptDes(message, key = "inheritech_key") {
      var cryptoJs = require("crypto-js");
      const keyHex = cryptoJs.enc.Utf8.parse(key);
      const decrypted = cryptoJs.DES.decrypt(
        message, // 若message是base64格式，则无需转16进制hex，直接传入message即可
        keyHex,
        {
          mode: cryptoJs.mode.ECB,
          padding: cryptoJs.pad.Pkcs7,
        }
      );
      return decrypted.toString(cryptoJs.enc.Utf8);
    },
    //监听订单金额的变化
    watchOrderAmount(val) {
      this.isDisabledConfirmTicketFlag = val;
    },
    // 点击新增回访
    addVisitInfo(row) {
      this.visitFormVisible = true;
      this.$nextTick(() => {
        this.$refs.visitForm?.loadInfo(row);
      });
    },
    queryScheduledFun() {
      // 已派单，待接乘客数据获取
      queryScheduledList({
        pageSize: this.dispatchedPageSize,
        currentPage: this.dispatchedCurrentPage,
        lineId: this.searchForm.lineId,
        departureDate: this.searchForm.startDate,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.dData = [];
          this.dData = res.data.list;
          this.dispatchedTotal = res.data.total;
        } else {
          this.$message.error(res.msg);
          this.dData = [];
        }
      });
    },
    dispatchedOnCurrentChange(val) {
      this.dispatchedCurrentPage = val;
      this.queryScheduledFun();
    },
    dispatchedOnSizeChange(val) {
      this.dispatchedPageSize = val;
      this.queryScheduledFun();
    },
    recurSion(num) {
      if (num < 90) {
        let Numb = (num += 5);
        this.serviceChargeList.push({
          label: Numb,
          value: Numb,
        });
        this.recurSion(Numb);
      } else {
        return;
      }
    },
    charterPopupClosePopupFun() {
      this.refundTotal = 0;
      this.ticketPrice = 0;
      this.serviceCharge = 0;
      this.charterPopupShow = false;
      this.cancelTicketObj = {};
      this.loading = false;
    },
    charterPopupConfirmFun() {
      if (!this.refundTotal) {
        this.$message({
          type: "error",
          showClose: true,
          message: "请选择退票订单！",
        });
        return;
      }
      this.loading = true;
      if (this.children) {
        this.$confirm(`包含${this.children}张携童票，确认退订`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          center: true,
        }).then(() => {
          this.confirmOpera();
        });
      } else {
        this.confirmOpera();
      }
    },
    confirmOpera() {
      cancelTicket({
        orderIds: this.cancelTicketObj.ids,
        refundProportion: this.serviceCharge,
        reason: this.ReasonForRefund,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.$message({
            type: "success",
            showClose: true,
            message: "退票成功!",
          });
          this.loading = false;
          this.charterPopupShow = false;
          this.ReasonForRefund = "";
          if (this.cancelTicketName) {
            this.getDataList(3);
          } else {
            this.getHistoryList();
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    textareaInputFun(val) {
      if (val != "") {
        this.SMSshow = true;
      } else {
        this.SMSshow = false;
      }
    },
    // 点击短信群发按钮
    SMSFun(item) {
      this.SMSObj = {
        departureDate: item.departureDate,
        departureTime: item.departureTime,
        startStation: item.startStation,
        endStation: item.endStation,
        driverName: item.driverName,
        driverPhone: item.driverPhone,
        id: item.id,
        shiftType:item.shiftType,
        startDepartureTime:item.startDepartureTime,
        endDepartureTime:item.endDepartureTime
      };
      this.SMStitle = "晚点短信群发";
      this.dialogVisible1 = true;
    },
    // 企业回显
    getAddGroupId(v) {
      if (v !== null) {
        this.form.companyIds = [v];
      } else {
        this.form.companyIds = [];
      }
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    // 点击左侧树时间按钮的时候
    carTreeFun(num, mode) {
      this.cardMaskShow = false;
      this.cardeployShow = true;
      if (mode == undefined) {
        this.rememberNAME = "";
        this.rememberINDEX = "";
        this.rememberJNDEX = "";
      }
      this.rememberNUM = num;
      this.activeChecked = "";
      this.carTreeClass.map((item, index) => {
        this.$set(this.carTreeClass, index, "carTree-li");
      });
      this.carTreeClass[num] = "carTree-li carTree-Active";
      let time = "";
      switch (num) {
        case 0:
          this.batchCar = true;
          time = this.GMTToStr(new Date());
          break;
        case 1:
          this.batchCar = true;
          time = this.GMTToStr(new Date().getTime() + 86400000);
          break;
        case 2:
          this.batchCar = true;
          time = this.GMTToStr(new Date().getTime() + 86400000 * 2);
          break;
        case 3:
          this.batchCar = false;
          break;
      }
      // 如果是初始化或者点击的数据变化
      if (mode == undefined) {
        this.renderCarTree(time);
      } else {
        // 如果是配车完后的数据更新
        this.renderCarTree(time).then((res) => {
          this.unData = [];
          // 如果记住的是线路
          if (this.rememberNAME == "line") {
            this.carTreeList.map((item, index) => {
              if (this.rememberINDEX == index)
                if (item.intercityOrderGroups != null)
                  this.unData = item.intercityOrderGroups;
            });
          } else if (this.rememberNAME == "time") {
            // 如果是线路下的时间点
            this.carTreeList.map((item, index) => {
              if (this.rememberINDEX == index) {
                item.children.map((jump, jndex) => {
                  if (this.rememberJNDEX == jndex) {
                    if (jump.intercityOrderGroups != null)
                      this.unData = jump.intercityOrderGroups;
                  }
                });
              }
            });
          }
        });
      }
    },
    // 选择车牌号   下拉框出现隐藏事件
    visibleChangeCarId(e, item) {
      if (e) {
        getAllVehicleList({ lineId: item.lineId }).then((res) => {
          if (res.code === "SUCCESS") {
            this.vehicleList = res.data;
          }
        });
      }
    },
    // 指派司机下拉框出现隐藏事件
    visibleChange(e, item) {
      if (e) {
        driverIntercityAPI(item.vehicleId).then((res) => {
          if (res.code == "SUCCESS") {
            this.driverList = res.data;
          }
        });
      }
    },
    // 弹窗确认按钮callback
    confirmFun(obj) {
      switch (this.popupNum) {
        // 批量配车确认回调
        case 1:
          let ays = [];
          this.marskArray.map((item) => {
            ays.push(this.unData[0].intercityOrders[item].id);
          });
          scheduleVehicle({
            vehicleId: obj.carValue,
            intercityOrderIds: ays,
          }).then((res) => {
            if (res.code == "SUCCESS") {
              if (res.code === "SUCCESS") {
                this.$message.success("配车成功");
                if (this.rememberNAME == "") {
                  this.carTreeFun(this.rememberNUM);
                } else {
                  this.carTreeFun(this.rememberNUM, "newRender");
                }
                // this.getDataList(1);
                this.marskArray = [];
                this.operAtionData = undefined;
                this.operAtionShow = false;
                this.cardMaskShow = false;
                this.cardeployShow = true;
              }
            } else {
              this.$message.error(res.msg);
            }
          });
          break;
      }
    },
    // 点击确认配车按钮
    cardeployFun() {
      if (this.marskArray.length > 0) {
        this.operAtionData = this.unData[0].intercityOrders[this.marskArray[0]];
        getAllVehicleList({ lineId: this.operAtionData.lineId }).then((res) => {
          if (res.code === "SUCCESS") {
            this.vehicleList = res.data;
            // 计算订单数量和乘客人数
            this.operAtionData["ORDERNUM"] = this.marskArray.length;
            this.operAtionData["PEOPRENUM"] = 0;
            this.marskArray.map((item) => {
              let num = this.unData[0].intercityOrders[item].validPassengersNum;
              this.operAtionData["PEOPRENUM"] += parseInt(num ? num : 0);
            });
            this.operAtionShow = true;
            this.operAtionText = "批量配车";
            this.popupNum = 1;
          }
        });
      } else {
        this.$message.error("请至少选择一个订单进行配车操作！");
      }
    },
    // 操作弹窗关闭按钮callback
    closePopupFun(val) {
      this.operAtionShow = val;
      this.operAtionData = undefined;
    },
    // 点击选中模板
    maskFun(index, item) {
      // 如果marskArray数组为空，直接批量添加相同的数据进去
      if (this.marskArray.length == 0) {
        this.marskArray.push(index);
        this.unData[0].intercityOrders.map((j, k) => {
          if (
            j.startStation == item.startStation &&
            j.endStation == item.endStation &&
            j.departureDate == item.departureDate &&
            j.departureTime == item.departureTime
          ) {
            if (this.marskArray.indexOf(k) == -1) {
              this.marskArray.push(k);
            }
          }
        });
      } else {
        if (this.marskArray.indexOf(index) == -1) {
          if (
            this.unData[0].intercityOrders[this.marskArray[0]].startStation ==
              item.startStation &&
            this.unData[0].intercityOrders[this.marskArray[0]].endStation ==
              item.endStation &&
            this.unData[0].intercityOrders[this.marskArray[0]].departureDate ==
              item.departureDate &&
            this.unData[0].intercityOrders[this.marskArray[0]].departureTime ==
              item.departureTime
          ) {
            if (this.marskArray.indexOf(index) == -1) {
              this.marskArray.push(index);
            }
          } else {
            this.$message.error("不同班次不可勾选！");
          }
        } else {
          this.marskArray.splice(this.marskArray.indexOf(index), 1);
        }
      }
    },
    onDeleteOrderFun(item) {
      deleteDriverOrder({ id: item.id }).then((res) => {
        if (res.code == "SUCCESS") {
          this.getDataList(2);
        }
      });
    },
    orderStatusTextFun(text) {
      if (text == "后台已退票") {
        return "已退票";
      } else {
        return text;
      }
    },
    // 点击标签选项卡的事件
    tabClickFun(val) {
      this.cardMaskShow = false;
      this.cardeployShow = true;
      this.marskArray = [];
      this.rememberTABLES = val.label;
      if (val.label == "待配车") {
        // this.getDataList(1);
        this.renderCarTree(this.GMTToStr(new Date()));
        this.carTreeClass = [
          "carTree-li carTree-Active",
          "carTree-li",
          "carTree-li",
          "carTree-li",
        ];
        this.rememberNAME = "";
        this.rememberINDEX = "";
        this.rememberJNDEX = "";
        this.rememberNUM = 0;
      } else if (val.label == "已配车，待派单") {
        this.getDataList(2);
      } else if (val.label == "已派单，待接乘客") {
        this.getDataList(3);
      } else if (val.label == "历史调度") {
        this.form.startDate = lastTime(new Date()) + " 00:00:00";
        let day = new Date();
        this.form.endDate =
          day.getFullYear() +
          "-" +
          (day.getMonth() + 1) +
          "-" +
          day.getDate() +
          " 23:59:59";
        this.getHistoryList();
      }
    },
    // 定时递归调用高亮显示
    timeFun(index) {
      if (this.timeoutOnofft == false) this.timeoutOnofft = true;
      let that_ = this;
      let times = setTimeout(function () {
        if (that_.timeNumber <= 7) {
          if (that_.marskArray.indexOf(index) == -1) {
            that_.marskArray.push(index);
            clearTimeout(times);
            that_.timeFun(index);
            that_.timeNumber++;
            document
              .querySelectorAll(".card")
              [index].classList.add("active-card");
          } else {
            that_.marskArray.splice(that_.marskArray.indexOf(index), 1);
            clearTimeout(times);
            that_.timeFun(index);
            that_.timeNumber++;
            document
              .querySelectorAll(".card")
              [index].classList.remove("active-card");
          }
        } else {
          that_.timeoutOnofft = false;
          that_.timeNumber = 0;
          clearTimeout(times);
          that_.$store.commit("PAIDORDERID", "");
        }
      }, 500);
    },
    // search() {
    //   this.getDataList(1).then(() => {
    //     console.log(this.$store.state.vehicle.paidOrderId);
    //     console.log(this.unData);
    //     this.unData.map((item, index) => {
    //       if (item.id == this.$store.state.vehicle.paidOrderId) {
    //         if (this.timeoutOnofft == false) {
    //           this.timeFun(index);
    //         }
    //       }
    //     });
    //   });
    // },
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.vehcileScheduling.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight - 16 - formHeight - paginationHeight - 10 - 100;
    },

    // 弹窗关闭时的回调
    onDialogClose() {
      this.dialogVisible = false;
    },
    // 弹窗关闭时的回调
    onDialogClose1() {
      this.addForm = {
        name: "",
      };
      this.dialogVisible1 = false;
      this.editLoading = false;
    },
    // 弹窗关闭时的回调
    onDialogClose2() {
      this.dialogVisible2 = false;
    },
    // 退票
    cancelTicket(row, name) {
      const id = row.orderId || row.intercityOrderId;
      referOrderDetialAPI(id).then((res) => {
        if (res.code === "SUCCESS") {
          if (row.totalPrices == 0) {
            this.$confirm("确认退票？", "提示", {
              center: true,
            }).then(() => {
              const obj = {
                orderIds: res.data.map((i) => i.id),
                refundProportion: 0,
                reason: "取消订单",
              };
              cancelTicket(obj).then((res) => {
                if (res.code == "SUCCESS") {
                  this.$message.success("退票成功");
                  this.getHistoryList();
                }
              });
            });
          } else {
            this.tableData2 = res.data;
            this.charterPopupShow = true;
            this.cancelTicketName = name;
          }
        }
      });
      // const h = this.$createElement;
      // this.$msgbox({
      //   title: "消息",
      //   type: "warning",
      //   message: h("p", null, [
      //     h("span", null, "您将全额退票"),
      //     h("span", null, "，是否退票？"),
      //   ]),
      //   showCancelButton: true,
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      // })
      //   .then(() => {
      //     cancelTicket({
      //       outTradeNo: row.intercityOrderId
      //         ? row.intercityOrderId
      //         : row.orderId,
      //     }).then((res) => {
      //       if (res.code === "SUCCESS") {
      //         this.$message({
      //           type: "success",
      //           showClose: true,
      //           message: "退票成功!",
      //         });
      //         if (name) this.getDataList(3);
      //         this.getHistoryList();
      //       } else {
      //         this.$message.error(res.msg);
      //       }
      //     });
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       showClose: true,
      //       message: "已取消退票",
      //     });
      //   });
    },
    select(selection) {
      this.ticketPrice = 0;
      this.cancelTicketObj.ids = [];
      this.children = 0;
      if (selection.length) {
        selection.forEach((e) => {
          this.ticketPrice +=
            e.preferentialType === 0 ? e.ticketPricesPer : e.totalPrices;
          this.cancelTicketObj.ids.push(e.id);
          this.children += e.carryingChildren;
        });
        this.refundTotal = this.serviceCharge
          ? ((this.ticketPrice * (100 - this.serviceCharge)) / 100).toFixed(2)
          : this.ticketPrice.toFixed(2);
      } else {
        this.ticketPrice = 0;
        this.refundTotal = 0;
      }
    },
    refundChange(val) {
      this.refundTotal = ((this.ticketPrice * (100 - val)) / 100).toFixed(2);
    },
    //查看乘车码
    checkCode(val) {
      this.showRideCode = true;
      checkCodeAPI({ orderId: val.intercityOrderId }).then((res) => {
        if (res.code == "SUCCESS") {
          this.rideCode = res.data;
        }
      });
    },

    //历史调度
    getHistoryList() {
      queryScheduleHistoryList(this.form).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.computeHeight();
        } else {
          this.$message.error(res.msg);
          this.tableData = [];
        }
      });
    },
    onExport() {
      historyExportAPI(this.form).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName =
          "城际专车历史调度订单 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");
        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    getGroupId(v) {
      this.form.lineId = v;
    },
    // 点击查询
    onSearch() {
      this.form.currentPage = 1;
      this.getHistoryList();
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
      this.getHistoryList();
    },
    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
      this.form.currentPage = 1;
      this.getHistoryList();
      this.computeHeight();
    },
    // 获取数据列表
    getDataList(num) {
      return new Promise((resolve, reject) => {
        if (num == 1) {
          // 待配车数据获取
          queryUnscheduleList({
            lineId: this.searchForm.lineId,
            departureDate: this.searchForm.startDate,
          }).then((res) => {
            if (res.code === "SUCCESS") {
              this.unData = res.data.list;
              resolve();
            } else {
              this.$message.error(res.msg);
              this.unData = [];
            }
          });
        } else if (num == 2) {
          // 已配车，待派单数据获取
          querySchedulingList({
            lineId: this.searchForm.lineId,
            departureDate: this.searchForm.startDate,
          }).then((res) => {
            if (res.code === "SUCCESS") {
              this.ingData = res.data.list;
            } else {
              this.$message.error(res.msg);
              this.upData = [];
            }
          });
        } else if (num == 3) {
          // 已派单，待接乘客数据获取
          this.dispatchedPageSize = 10;
          this.dispatchedCurrentPage = 1;
          this.queryScheduledFun();
        }
      });
    },
    // 查询线路详情
    // handleNodeClick (node) {
    //   if (node.type === 7) {
    //     this.showDeatil = true
    //     this.shiftIntercityId = node.id
    //     this.getDataList()
    //   } else {
    //     this.showDeatil = false
    //   }
    // },
    // 获取线路
    getLineList() {
      // { companyId: sessionStorage.getItem("companyId") }
      getAllLine().then((res) => {
        if (res.code === "SUCCESS") {
          this.lineList = res.data;
        }
      });
    },
    // 获取查看所有车辆列表
    getAllVehicleList() {
      getAllVehicleList().then((res) => {
        if (res.code === "SUCCESS") {
          this.vehicleList = res.data;
        }
      });
    },
    // 获取所有的司机
    getAllDriverList() {
      getAllDriverList().then((res) => {
        if (res.code === "SUCCESS") {
          this.driverList = res.data;
        }
      });
    },
    // 配车
    onVehicle(row) {
      scheduleVehicle({
        vehicleId: row.vehicleId,
        intercityOrderIds: [row.id],
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.$message.success("配车成功");
          // this.getDataList(1);
          if (this.rememberNAME == "") {
            this.carTreeFun(this.rememberNUM);
          } else {
            this.carTreeFun(this.rememberNUM, "newRender");
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 取消配车
    cancelVehicle(row) {
      cancelVehicle({ intercityOrderId: row.intercityOrderId }).then((res) => {
        if (res.code === "SUCCESS") {
          this.$message.success("取消配车成功");
          querySchedulingDetail({ driverOrderId: this.detailRow.id }).then(
            (res) => {
              if (res.code === "SUCCESS") {
                this.vehicleData = res.data;
                this.dialogVisible = true;
              }
            }
          );
          this.getDataList(2);
        }
      });
    },
    // 取消接驾
    cancelOrder(row, item) {
      this.addForm.intercityOrderId = row.intercityOrderId;
      this.SMSshow = false;
      this.SMStitle = "取消接驾";
      this.dialogVisible1 = true;
    },
    // 取消接驾确认
    cancelOrderSave() {
      if (this.addForm.name == "") {
        this.$message.error("原因必填！");
      } else {
        if (this.SMStitle == "取消接驾") {
          cancelOrder({
            intercityOrderId: this.addForm.intercityOrderId,
            orderAdditionalInformation: this.addForm.name,
          }).then((res) => {
            if (res.code === "SUCCESS") {
              this.$message.success("取消接驾成功");
              this.dialogVisible1 = false;
              this.getDataList(3);
            }
          });
        } else if (this.SMStitle == "晚点短信群发") {
          lateNoticeAPI({
            driverOrderId: this.SMSObj.id,
            orderAdditionalInformation: this.addForm.name,
          }).then((res) => {
            if (res.code === "SUCCESS") {
              this.$message.success("保存成功");
              this.dialogVisible1 = false;
            }
          });
        }
      }
    },
    // 派单
    onDriver(row) {
      scheduleDriver({ driverId: row.driverId, driverOrderId: row.id }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.$message.success("派单成功");
            this.getDataList(2);
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    // 配车详情
    onDetail(row) {
      this.detailRow = row;
      querySchedulingDetail({ driverOrderId: row.id }).then((res) => {
        if (res.code === "SUCCESS") {
          this.vehicleData = res.data;
          this.dialogVisible = true;
        }
      });
    },
    onHistoryDetail(row) {
      queryScheduleHistoryDetail({ orderId: row.orderId }).then((res) => {
        if (res.code === "SUCCESS") {
          if (res.data.length === 0) {
            this.$message.warning("暂无调度数据！");
          } else {
            this.historyData = res.data;
            this.dialogVisible3 = true;
          }
        }
      });
    },
    openPopupFun(item) {
      this.dataPopup = item;
      referOrderDetialAPI(item.orderId).then((res) => {
        if (res.code === "SUCCESS") {
          this.tablePopupShow = true;
          this.tableData2 = res.data;
        }
      });
    },
    //v7.1改签
    ticketChangeFun(row) {
      if (row.purchaseMethod == 4 && row.totalPrices == 0) {
        this.$router.push({
          name: "telebooking",
          params: { id: row.orderId },
        });
        return;
      }
      this.$route.meta.title = "改签";
      getOrderInfoAPI({ orderId: row.orderId }).then((res) => {
        if (res.code === "SUCCESS") {
          this.ticketChangeForm = res.data;
          this.isShowTicketChange = true;
        }
      });
    },
    //返回按钮
    returnFun(flag) {
      this.$route.meta.title = "城际专车调度";
      this.ticketChangeActive = 0;
      this.isShowTicketChange = false;
      if (flag === "isRequest") {
        this.getHistoryList();
      }
    },
    //步骤条下一步
    nextFun() {
      this.ticketChangeActive = 1;
    },
    //步骤条上一步
    previousFun() {
      this.$refs.phoneOrderDetialRef.$refs.ruleForm.resetFields();
      this.$refs.phoneOrderDetialRef.getTicketChangeFormFun();
      this.ticketChangeActive = 0;
    },
    //确认改签
    confirmTicketChangeFun() {
      // let childForm = this.$refs.phoneOrderDetialRef.$data.form;
      // if (childForm.oldTotalPrices !== childForm.totalPrices) {
      //   this.$message.error("改签前后的价格不一致，不能改签！");
      //   return;
      // }
      this.$refs.phoneOrderDetialRef.placeOrder();
    },
    placeOrder(val) {
      if (val == "SUCCESS") {
        this.isTicketChange = true;
      } else {
        this.isTicketChange = false;
      }
      this.ticketChangeActive = 2;
    },
    closeFun() {
      this.tablePopupShow = false;
    },
    onCheck(row) {
      this.detailRow = row;
      this.dialogVisible2 = true;
      this.mapDriverObj = {
        driverId: row.driverId,
        licensePlateColorStr: row.licensePlateColorStr,
        licensePlateNumber: row.licensePlateNumber,
      };
    },
    //v7.1调度改派
    changeDispatchFun(item) {
      this.dispatchForm = JSON.parse(JSON.stringify(item));
      this.getAllVehicleListFun(this.dispatchForm.lineId);
      //旧的车辆ID、司机ID
      this.dispatchForm.oldVehicleId = this.dispatchForm.vehicleId;
      this.dispatchForm.oldDriverId = this.dispatchForm.driverId;
      //弹窗不回显
      this.dispatchForm.driverId = "";
      this.dispatchForm.vehicleId = "";
      this.dialogVisible4 = true;
    },
    //获取指派车辆
    getAllVehicleListFun(lineId) {
      getAllVehicleList({ lineId }).then((res) => {
        if (res.code === "SUCCESS") {
          this.dispatchCarList = res.data;
        }
      });
    },
    //获取指派司机
    getDriverIntercityFun(vehicleId) {
      driverIntercityAPI(vehicleId + "?isLock=-1").then((res) => {
        if (res.code == "SUCCESS") {
          this.dispatchDriverList = res.data;
        }
      });
    },
    //确认改派
    confirmChangeFun() {
      if (!this.dispatchForm.vehicleId) {
        this.$message.warning("请选择指派车辆");
        return;
      }
      if (!this.dispatchForm.driverId) {
        this.$message.warning("请选择指派司机");
        return;
      }
      let params = {
        driverOrderId: this.dispatchForm.id,
        oldVehicleId: this.dispatchForm.oldVehicleId,
        oldDriverId: this.dispatchForm.oldDriverId,
        vehicleId: this.dispatchForm.vehicleId,
        driverId: this.dispatchForm.driverId,
      };
      updateDispatchOfDriverOrCarAPI(params).then((res) => {
        if (res.code == "SUCCESS" && res.data) {
          this.dialogVisible4 = false;
          this.getDataList(3);
        }
      });
    },
    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span>{node.label}</span>
          <span style="color:red"> ({data.passengerCount})</span>
        </span>
      );
    },
    // 左侧树数据
    renderCarTree(time) {
      return new Promise((resolve) => {
        carTreeAPI({
          currentPage: 1,
          pageSize: 1000,
          departureDate: time,
        }).then((res) => {
          this.datacarTree = res.data;
          this.unData = res.data.intercityOrderGroups;
          let list = [];
          res.data.children.map((item) => {
            let arrays = [];
            item.children.map((jump) => {
              arrays.push({
                label: jump.label,
                passengerCount: jump.passengerCount,
                ids: this.createdUUid(),
                intercityOrderGroups: jump.intercityOrderGroups,
              });
            });
            list.push({
              label: item.label,
              children: arrays,
              passengerCount: item.passengerCount,
              ids: this.createdUUid(),
              intercityOrderGroups: item.intercityOrderGroups,
            });
          });
          this.carTreeList = list;
          resolve();
        });
      });
    },
    // 点击左侧菜单树样式变化和数据渲染
    checkedNodeTree(ids, name, data, index, jndex) {
      this.cardMaskShow = false;
      this.cardeployShow = true;
      this.rememberNAME = name;
      this.rememberINDEX = index;
      if (jndex != undefined) {
        this.rememberJNDEX = jndex;
      }
      // 记住当前点击的节点类型名称和对应index
      this.activeChecked = ids;
      if (data != null) {
        this.unData = data;
      } else {
        this.unData = [];
      }
    },
  },
  created() {
    // 获取各种下拉列表的数据
    this.getCompanyList();
    this.getLineList();
    this.getAllVehicleList(); //  车辆列表
    // this.renderCarTree(this.GMTToStr(new Date()));//只显示已派单乘客
    // this.getAllDriverList();
  },
  mounted() {
    this.recurSion(0);
    this.$nextTick(() => {
      window.addEventListener("resize", this.computeHeight, false);
    });
    // this.search();
    //只显示已派单乘客
    this.getDataList(3);
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dropdown-link {
  cursor: pointer;
  color: #336ffe;
}

/deep/.el-icon-arrow-down {
  font-size: 12px;
}
.dispatch-form {
  .el-form-item {
    margin-bottom: 0;
  }
}

.feeBox {
  display: flex;
  margin: 40px 0;
  justify-content: space-between;

  p {
    padding-bottom: 10px;
    font-size: 14px;
  }

  /deep/.el-input__inner {
    width: 300px;
  }
}

.reasonBox {
  margin: 40px 0;

  p {
    padding-bottom: 10px;
    font-size: 14px;
  }

  /deep/.el-input__inner {
    width: 300px;
  }
}

.colorSpan {
  color: #86888b !important;
}

.contentGridnoData {
  width: 100%;
  text-align: center;
  font-size: 16px;
}

/deep/.el-select {
  input {
    height: 32px !important;
  }
}

.passengerSpan {
  color: red !important;
  display: inline !important;
  padding-left: 0px !important;
}

.active {
  background: #e8e8e8 !important;
  color: #336ffe !important;
}

/deep/.expanded {
  pointer-events: none;
}

.carTree-content {
  height: calc(100% - 50px);
  overflow-x: hidden;
  overflow-y: auto;

  p {
    font-size: 14px;
    color: #333333;
    width: 100%;
    min-height: 24px;
    margin-bottom: 4px;
    cursor: pointer;
    padding-left: 20px;
    line-height: 22px;
    box-sizing: border-box;

    i {
      color: #333333;
      font-size: 18px;
    }
  }

  span {
    line-height: 24px;
    margin-bottom: 4px;
    cursor: pointer;
    width: calc(100% - 50px);
    height: 24px;
    display: inline-block;
    padding-left: 50px;
    color: #606266;
  }
}

.carTree-btn {
  height: 40px;
  width: 100%;
  margin-bottom: 10px;

  .carTree-Active {
    color: #ffffff !important;
    background: #336ffe;
  }

  .carTree-li {
    width: calc(25% - 1px);
    height: 32px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    color: #909399;
    line-height: 32px;
    font-size: 14px;
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
  }
}

/deep/.el-tabs__content {
  height: calc(100% - 70px);
}

.el-tabs {
  height: 100%;
}

.el-tab-pane1 {
  display: flex;
  height: 100%;
}

.carContent {
  display: inline-block;
  width: calc(100% - 220px);
  margin-left: 20px;
  height: 100%;

  .el-form {
    grid-template-columns: repeat(3, 33.3%);
  }

  .el-row {
    height: 100%;
  }

  .el-col {
    height: 100%;
  }
}

.carTree {
  display: inline-block;
  box-shadow: 0px 0px 1px 0px #b4b2b2;
  transition: box-shadow 0.3s;
  -moz-transition: box-shadow 0.3s;
  -webkit-transition: box-shadow 0.3s;
  width: 200px;
  height: 100%;
  background: #ffffff;
}

.active-mask {
  border: 2px solid #336ffe;
}

.Table {
  margin-top: 16px;
}

.textareaInputs {
  width: 100%;

  /deep/ textarea {
    width: 100% !important;
    height: 60px !important;
    resize: none !important;
  }
}

.SMSpreview {
  background: #ffffff;
  padding: 5px 15px;
  border: 1px solid #d3d6dd;
  border-radius: 6px;
  line-height: 20px;
}

.content {
  width: 100%;
}

.ticket-change-page {
  margin-top: 50px;
  position: relative;
  height: calc(100% - 32px - 50px) !important;

  .ticket-change {
    width: 100%;
    height: calc(100% - 32px);
    overflow: scroll;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;

    .exit {
      width: 32px;
      height: 32px;
      color: #333333;
      font-size: 15px;
      background-color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
      position: fixed;
      top: 80px;

      i {
        cursor: pointer;
      }
    }

    .title {
      width: 100%;
      height: 56px;
      line-height: 56px;
      padding-left: 10px;
      margin-bottom: 50px;
      border-bottom: 1px solid #dcdedf;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      box-sizing: border-box;
    }

    .button-box {
      border-top: 1px solid #dcdedf;
      padding-top: 16px;
      display: flex;
      justify-content: center;
    }

    .result-box {
      width: 100%;
      height: 560px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;

      div {
        display: flex;
        align-items: center;

        span {
          margin-left: 12px;
        }
      }

      .success-text {
        color: #2da641;
      }

      .error-text {
        color: #d40000;
      }
    }

    ::v-deep .is-text {
      border: none;
      color: #ffffff;
    }

    ::v-deep .el-step__main {
      .is-wait {
        color: #969799;
      }

      .el-step__description {
        color: #969799;
      }

      .el-step__title {
        color: #303133;
      }
    }

    ::v-deep .is-process {
      .is-text {
        background: #336ffe;
      }
    }

    ::v-deep .is-wait {
      .is-text {
        background: #c8c9cc;
      }
    }

    ::v-deep .is-success {
      .is-text {
        border: 2px solid #336ffe;
        color: #336ffe;
      }

      .el-step__line {
        border-color: #336ffe;
        background-color: #336ffe;
      }
    }
  }
}

.vehcile-scheduling {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #f7f8f9;

  .el-divider--horizontal {
    margin: 5px 0;
  }

  .scheduleHistoryDetail {
    line-height: 24px;
  }

  .contentGrid2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px 20px;

    .card {
      border: 1px solid #e9e9e9;
      padding: 10px;

      .line {
        color: #333333;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
      }

      .btn {
        .el-button {
          margin-top: 10px;
        }
      }
    }
  }

  .currentCode {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
  }

  .contentGrid {
    line-height: 25px;
    margin-top: 10px;
    height: calc(100% - 70px);
    overflow: auto;
    // padding-right: 3px;
    overflow-x: hidden;

    .card-content {
      width: 99.4%;
      border: 1px solid #dbe2f3;
      border-radius: 4px;
      background: #ffffff;
      margin-bottom: 16px;

      .card-label {
        height: 40px;
        width: 100%;

        p {
          width: 100%;
          background: #dbe2f3;
          border-radius: 4px 4px 0 0;
          height: 100%;
          text-align: center;

          .Date {
            float: left;
            margin-left: 20px;
          }

          .total {
            float: right;
            margin-right: 54px;

            div {
              display: inline-block;
              font-size: 12px;
              color: #666666;
              font-weight: 400;
            }
          }

          span {
            line-height: 40px;
            font-weight: bold;
            margin-right: 18px;
            font-size: 15px;
            color: #333333;
          }
        }
      }

      .gridContent {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px 20px;
        padding: 10px;
        width: 100%;

        .active-card {
          border: 2px solid #336ffe !important;
        }

        .card {
          border: 1px solid #e9e9e9;
          padding: 10px;
          // height: 38vh;
          position: relative;

          .mask {
            position: absolute;
            width: 100%;
            height: 100%;
            top: -1px;
            left: 0;
            z-index: 555;
            cursor: pointer;
            background: rgba(245, 245, 245, 0.3);
          }

          .mask:hover {
            border: 2px solid #336ffe;
          }

          .line {
            color: #333333;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
          }

          .time {
            color: #336ffe;
            font-size: 14px;
          }

          .btn {
            margin-top: 4px;
          }

          .el-button {
            margin-left: 5px;
          }
        }

        span {
          color: #267dff;
        }
      }
    }
  }

  .tableGrid {
    grid-template-columns: repeat(1, 1fr);
    overflow-y: auto;
    height: calc(100vh - 330px);

    .card {
      border: 1px solid #e9e9e9;
      margin-bottom: 10px;
    }

    .el-button {
      margin-left: 0;
      margin-right: 10px;
    }

    .el-button:nth-child(3) {
      padding: 0 0 9px 0;
    }
  }
}

.theme-project-gps {
  .vehcile-scheduling {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  .desc {
    color: red;
    margin-right: 30px;
    font-size: 15px;
  }
}
</style>
